import { Injectable } from '@angular/core';
import { DateAdapter } from '@angular/material/core';

import { TranslateService } from '@ngx-translate/core';

import { Locale } from '../models';

@Injectable()
export class LocaleService {
  private STORAGE_LOCALE_KEY = 'current-locale';

  constructor(
    private translateService: TranslateService,
    private adapter: DateAdapter<Date>
  ) {
  }

  changeLocale(locale: Locale | null = null): void {
    if (!locale) {
      locale = this.getCurrentLocale();
    }
    window.localStorage[this.STORAGE_LOCALE_KEY] = locale;
    this.translateService.setDefaultLang(locale);
    this.translateService.use(locale);
    this.adapter.setLocale(locale);
  }

  getCurrentLocale(): Locale {
    return window.localStorage[this.STORAGE_LOCALE_KEY] ?? Locale.en;
  }
}
