import {
  AfterViewInit,
  Component,
} from '@angular/core';

import {
  AppService,
  Team,
  TeamsService,
} from '../../../core';
import { AjaxDataSource } from '../../../core/services/ajax.datasource';
import { DataTableComponent } from '../../components/data-table/data-table.component';

@Component({
  selector: 'app-teams-listing',
  templateUrl: './teams.component.html',
  styleUrl: './teams.component.scss',
})
export class TeamsComponent extends DataTableComponent implements AfterViewInit {
  loading = false;
  displayedColumns: Array<string> = ['name', 'url', 'action'];
  datasource: AjaxDataSource<Team>;
  defaultFilters: {q: string} = { q: '' };
  filterService: string = 'teamsService';
  filterStorage: string = '_filterAdminTeams';

  constructor(
    private appService: AppService,
    private teamsService: TeamsService,
  ) {
    super();
    this.appService.title = 'pages.teams.list';
    this.appService.active = 'teams';
    this.datasource = new AjaxDataSource<Team>();
  }

  reload() {
    this.updateDataSource(this.teamsService.fetchTeams(this.ajaxParams.getHttpParams()));
  }
}
