export enum SocialProvider {
  Google = 'google',
  Facebook = 'facebook',
  Github = 'github',
  Linkedin = 'linkedin',
  Gitlab = 'gitlab',
  Bitbucket = 'bitbucket',
}

export interface Social {
  link: string;
}

export interface UserSocialite {
  email?: string,
  provider_name?: SocialProvider,
}
