<div class="px-0 px-lg-5 justify-content-center">
  @if (loading) {
    <div class="d-flex justify-content-center">
      <mat-spinner></mat-spinner>
    </div>
  }
  @if (!loading) {
    <form [formGroup]="form">
      <fieldset [disabled]="isSubmitting">
        <div class="row justify-content-center mb-3">
          <div
            [ngClass]="sbOpen ? 'col-12 col-xxl-4 col-xl-5 col-lg-10 mb-3 mb-xl-0' : 'col-12 col-lg-5 col-xxl-4 col-md-9 mb-3 mb-lg-0'">
            <div class="profile-card">
              <div class="profile-card__header">
                <div class="profile-card__header__title">
                  {{ 'actions.userProfile.avatarTitle' | translate }}
                </div>
              </div>
              <div class="profile-card__body">
                <div class="row justify-content-center">
                  <div class="avatar-container">
                    <img class="img-avatar"
                         [src]="user.avatar ? user.avatar.presets['500x500'] : '../../../../assets/icons/person.svg'"
                         alt="">
                  </div>
                </div>
                <div class="row justify-content-center mx-0">
                  <div class="avatar-upload-container">
                    <ul class="avatar-upload-container__hint">
                      <li>{{ 'actions.userProfile.avatarFileFormats' | translate }}</li>
                      <li>{{ 'actions.userProfile.avatarFileSize' | translate }}</li>
                    </ul>
                    <div class="avatar-upload-container__upload" (click)="fileInput.click()">
                      <div class="avatar-upload-container__upload__link">
                        {{ 'actions.userProfile.avatarUpload' | translate }}
                        <mat-icon>upload</mat-icon>
                      </div>
                      <input hidden type="file" #fileInput (change)="uploadAvatar(fileInput.files)"/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div [ngClass]="sbOpen ? 'col-12 col-xxl-5 col-xl-6 col-lg-10' : 'col-12 col-lg-7 col-xl-6 col-md-9'">
            <div class="profile-card">
              <div class="profile-card__header">
                <div class="profile-card__header__title">
                  {{ 'actions.userProfile.generalTitle' | translate }}
                </div>
              </div>
              <div class="profile-card__body">
                <app-input formControlName="first_name"
                           [config]="{ id: 'first_name', label: 'firstName', class: 'w-100' }"
                           [errors]="form.get('first_name').errors"
                           [required]="true"
                ></app-input>
                <app-input formControlName="last_name"
                           [config]="{ id: 'last_name', label: 'lastName', class: 'w-100' }"
                           [errors]="form.get('last_name').errors"
                           [required]="true"
                ></app-input>
                <mat-form-field appearance="fill" class="w-100">
                  <mat-label>{{ 'form.dateOfBirth.label' | translate }}</mat-label>
                  <input matInput [matDatepicker]="picker" [max]="maxDateOfBirth" formControlName="date_of_birth"
                         (click)="picker.open()">
                  <mat-hint>{{ 'form.dateOfBirth.hint' | translate }}</mat-hint>
                  <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                  <mat-datepicker #picker></mat-datepicker>
                  @if (form.get('date_of_birth')?.errors) {
                    <mat-error>
                      <app-validation-error-render label="dateOfBirth"
                                                   [errors]="form.controls.date_of_birth?.errors">
                      </app-validation-error-render>
                    </mat-error>
                  }
                </mat-form-field>
                <app-input formControlName="email"
                           [config]="{ id: 'email', label: 'email', type: 'email', class: 'w-100' }"
                           [isDisabled]="true"
                ></app-input>
                <mat-form-field appearance="fill" class="w-100">
                  <mat-label>{{ 'form.password.label' | translate }}</mat-label>
                  <input matInput value="********" [disabled]="true">
                  <button mat-icon-button
                          matSuffix (click)="openDialog()"
                          [attr.aria-label]="'actions.hidePassword' | translate">
                    <mat-icon class="hide-password-icon">mode_edit</mat-icon>
                  </button>
                </mat-form-field>
                <div class="d-flex justify-content-end">
                  <button mat-flat-button color="primary" type="submit" class="submit" (click)="submitForm()"
                          [disabled]="isSubmitting || !form.valid || !form.touched">
                    {{ 'actions.save' | translate }}
                  </button>
                </div>
              </div>
            </div>
            <div class="profile-card">
              <div class="profile-card__header">
                <div class="profile-card__header__title">
                  {{ 'actions.userProfile.socialTitle' | translate }}
                </div>
              </div>
              <div class="profile-card__body">
                <app-social-buttons [isProfile]="true"></app-social-buttons>
              </div>
            </div>
          </div>
        </div>
      </fieldset>
    </form>
  }
</div>

<ng-template #progressBar>
  <div class="d-flex align-items-center">
    <span>0%</span>
    @if ((upload$ | async); as upload) {
      <mat-progress-bar
        [mode]="upload.state === 'PENDING' ? 'buffer' : 'determinate'"
        [value]="upload.progress"
        class="mx-2"
      >
      </mat-progress-bar>
    }
    <span>100%</span>
  </div>
</ng-template>
