import {
  Component,
  OnInit,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import {
  ActivatedRoute,
  Router,
} from '@angular/router';

import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngxs/store';

import {
  AppService,
  HelperPrepareParamsService,
  RequestIds,
  Team,
  TeamInviteService,
  TeamsService,
  User,
  UserService,
} from '../../../core';
import { HidePassword } from '../../../core/models/icon.model';
import { ErrorsState } from '../../index';

@Component({
  selector: 'app-team-user-invite',
  templateUrl: './invite.component.html',
  styleUrls: ['./invite.component.scss', '../user-profile/user-profile.component.scss'],
})
export class TeamInviteComponent implements OnInit {
  team: Team;
  createForm: FormGroup;
  isSubmitting = false;
  isLoading = true;
  passwordField = true;
  hidePassword = true;

  constructor(
    private teamService: TeamsService,
    private teamInviteService: TeamInviteService,
    private fb: FormBuilder,
    private store: Store,
    private route: ActivatedRoute,
    private appService: AppService,
    private translateService: TranslateService,
    private userService: UserService,
    private router: Router,
  ) {
    this.createForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      first_name: ['', [Validators.required]],
      last_name: ['', [Validators.required]],
    });
  }

  ngOnInit() {
    const teamId = parseInt(this.route.snapshot.paramMap.get('id'));
    this.teamService.getTeam(teamId).subscribe({
      next: (team: Team) => {
        this.team = team;

        this.appService.title = this.translateService.instant('pages.users.teamInvite', {
          teamName: this.team.name,
        });

        this.createForm.addControl('password', this.fb.control('', [
          Validators.required,
          Validators.minLength(6),
        ]));

        this.isLoading = false;
      },
      error: () => {
        this.router.navigateByUrl('/teams');
      },
    });
  }

  get passIcon() {
    return this.hidePassword ? HidePassword.OFF : HidePassword.ON;
  }

  submitForm(isInvite: boolean = false) {
    if (!this.createForm.valid) {
      return;
    }
    this.isSubmitting = true;
    const params = HelperPrepareParamsService.prepareParams(this.createForm.value);
    const service = this.teamInviteService.registerAndInvite(this.team.id, params);
    service.subscribe({
      next: (data) => {
        this.userService.currentUser.subscribe({
          next: (user: User): void => {
            if (user.email === this.createForm.get('email').getRawValue()) {
              this.userService.populate();
            }
          },
        });

        this.router.navigateByUrl('/teams/' + this.team.id);
      },
      error: () => {
        const form = this.createForm;
        const requestId = RequestIds.USER_CREATE;
        const errors = this.store.selectSnapshot(ErrorsState.errors(requestId));
        for (const key in errors) {
          form.controls[key]?.setErrors({ invalid: true, message: errors[key] });
        }
        this.isSubmitting = false;
      },
    });
  }
}
