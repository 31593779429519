import { User } from './user.model';

export enum TeamMemberRoles {
  HEAD = 'head',
  MEMBER = 'member',
}

export interface TeamMember {
  id: number;
  team_id: number;
  role: TeamMemberRoles;
  created_at?: string | Date;
  updated_at?: string | Date;
  deleted_at?: string | Date;
  member: null | User;
  member_id: number;
  member_type: string;
}

