import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Store } from '@ngxs/store';
import {
  Observable,
  throwError as observableThrowError ,
} from 'rxjs';
import {
  catchError,
  tap,
} from 'rxjs/operators';

import {
  REQUEST_ID,
  SHOW_REQUEST_ERROR,
} from './error.context';
import {
  ClearErrors,
  SetErrors,
} from './errors.actions';

import { ToastsService } from '../../core';

@Injectable()
export class ErrorHandlerInterceptor implements HttpInterceptor {
  constructor(
    private store: Store,
    private toastsService: ToastsService,
  ) {
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const requestId = request.context.get(REQUEST_ID);
    const showErr = request.context.get(SHOW_REQUEST_ERROR);
    return next.handle(request).pipe(
      tap(() => {
        if (requestId) {
          this.store.dispatch(new ClearErrors(requestId));
        }
      }),
      catchError((err: HttpErrorResponse | null) => {
        if (err && requestId && err.status === 422 && err?.error?.data) {
          this.store.dispatch(new SetErrors(requestId, err.error.data));
        }
        if (showErr && err?.error?.error && err.status && err.status !== 401) {
          this.toastsService.add(err.error.error, null, 5000);
        }
        return observableThrowError(() => err);
      }),
    );
  }
}
