import {
  HttpContext,
  HttpParams,
} from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApiService } from './api.service';
import { BaseService } from './base.service';

import { REQUEST_ID } from '../../shared';
import {
  BaseResponse,
  Comment,
  PaginationContent,
} from '../models';

@Injectable({
  providedIn: 'root',
})
export class CommentsService extends BaseService {
  protected readonly urlPath: string = '/support/comments';

  public constructor(private apiService: ApiService) {
    super();
  }

  public fetchComments(params?: HttpParams): Observable<PaginationContent<Comment>> {
    if (!params) {
      params = new HttpParams();
    }

    return this.apiService.get<PaginationContent<Comment>>(this.urlPath, params)
      .pipe(map((response: BaseResponse<PaginationContent<Comment>>) => response.data));
  }

  public createComment(params: object): Observable<Comment> {
    const context = new HttpContext().set(REQUEST_ID, this.urlPath);

    return this.apiService.post<Comment>(this.urlPath, params, false, context)
      .pipe(map(
        response => {
          if (response.data) {
            return response.data;
          }
        }
      ));
  }

  public getComment(id: number | string): Observable<Comment> {
    return this.apiService.get<Comment>(this.urlPath + `/${id}`).pipe(map(response => response.data));
  }

  public updateComment(id: number, params: object, upload = false): Observable<Comment> {
    const context = new HttpContext().set(REQUEST_ID, this.urlPath);

    return this.apiService.patch<Comment>(this.urlPath + `/${id}`, params, upload, context)
      .pipe(map(
        response => {
          if (response.data) {
            return response.data;
          }
        }
      ));
  }

  public deleteComment(id: number | string): Observable<BaseResponse<null>> {
    return this.apiService.delete<null>(this.urlPath + `/${id}`);
  }
}
