import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import {
  Injectable,
  Injector,
} from '@angular/core';
import { Router } from '@angular/router';

import {
  Observable,
  throwError as observableThrowError,
  Subject,
} from 'rxjs';
import {
  catchError,
  finalize,
  switchMap,
} from 'rxjs/operators';

import {
  JwtService,
  LocaleService,
  ToastsService,
  UserService,
} from '../../core';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  private isRefreshingToken = false;
  private refreshingPromise: Promise<void> | undefined;
  private refreshingPromiseResolver: ((value: void | PromiseLike<void>) => void) | undefined;
  private refreshingPromiseRejecter: ((reason?: any) => void) | undefined;

  constructor(
    private inj: Injector,
    private userService: UserService,
    private router: Router,
    private toastsService: ToastsService,
    private jwtService: JwtService,
    private localeService: LocaleService,
  ) {
  }

  authenticateRequest(req: HttpRequest<any>): HttpRequest<any> {
    const headersConfig: { [name: string]: string | string[]; } = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Accept-Language': this.localeService.getCurrentLocale(),
      'ngsw-bypass': 'true',
    };
    if (req.reportProgress) {
      delete headersConfig['Content-Type'];
    }
    const token = this.jwtService.getAccessToken();

    if (token && !req.url.endsWith('auth/refresh')) {
      headersConfig['Authorization'] = `Bearer ${token}`;
    }
    return req.clone({ setHeaders: headersConfig });
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const authReq = this.authenticateRequest(req);

    return next.handle(authReq).pipe(
      catchError((error, caught) => {
        if (error instanceof HttpErrorResponse) {
          switch (error.status) {
            case 401:
              return this.handler401Error(req, next, error);
          }
          return observableThrowError(() => error);
        } else {
          // return the error to the method that called it
          return observableThrowError(() => error);
        }
      })
    );
  }

  handler401Error(req: HttpRequest<any>, next: HttpHandler, error: HttpErrorResponse): Observable<HttpEvent<any>> {
    if (!error.url.endsWith('auth/refresh')) {
      if (!this.isRefreshingToken) {
        if (!this.userService.isAuthenticatedSubject.value) {
          return observableThrowError(() => error);
        }
        this.isRefreshingToken = true;
        this.preparePromise();
        return this.jwtService.refreshToken().pipe(
          switchMap(success => {
            if (success) {
              if (this.refreshingPromiseResolver) {
                this.refreshingPromiseResolver();
              }
              req = this.authenticateRequest(req);
              return next.handle(req);
            }
            if (this.refreshingPromiseRejecter) {
              this.refreshingPromiseRejecter(error);
            }
            this.userService.purgeAuth();
            this.router.navigateByUrl('/login');
            return observableThrowError(() => error);
          }),
          catchError((err, caught) => {
            if (this.refreshingPromiseRejecter) {
              this.refreshingPromiseRejecter(err);
            }
            this.userService.purgeAuth();
            this.router.navigateByUrl('/login');
            return observableThrowError(() => err);
          })
        ).pipe(finalize(() => {
          this.isRefreshingToken = false;
        }));
      } else {
        const subject: Subject<HttpEvent<any>> = new Subject();
        if (this.refreshingPromise) {
          this.refreshingPromise.then(() => {
            req = this.authenticateRequest(req);
            next.handle(req).subscribe({
              next: event => { subject.next(event) },
              error: err => { subject.error(err) },
            });
          });
        }
        return subject;
      }
    }
    return observableThrowError(() => error);
  }

  preparePromise() {
    this.refreshingPromise = new Promise((resolve, reject) => {
      this.refreshingPromiseResolver = resolve;
      this.refreshingPromiseRejecter = reject;
    });
  }
}
