import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
} from '@angular/router';

import { TranslateService } from '@ngx-translate/core';
import {
  Observable,
  of,
} from 'rxjs';

import {
  AppAbility,
  ToastsService,
  UserService,
} from '../../core';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private userService: UserService,
    private ability: AppAbility,
    private toastsService: ToastsService,
    private translateService: TranslateService,
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    if (!this.userService.isAuthenticatedSubject.getValue()) {
      this.router.navigateByUrl('/login');
      return of(false);
    }

    if (route.data['acl'] && this.ability.rules.length) {
      if (!this.ability.can(route.data['acl'][1], route.data['acl'][0])) {
        this.toastsService.add(this.translateService.instant('messages.notAuthorized'));
        this.router.navigateByUrl('/');
        return of(false);
      }
      return of(false);
    }

    return of(true);
  }
}
