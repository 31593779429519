import { HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { ApiService } from './api.service';
import { BaseService } from './base.service';

import { REQUEST_ID } from '../../shared';

@Injectable({
  providedIn: 'root',
})
export class TeamInviteService extends BaseService {
  protected readonly urlPath: string = '/users/invite';

  constructor(private apiService: ApiService) {
    super();
  }

  registerAndInvite(teamId: number, params: object) {
    const context = new HttpContext().set(REQUEST_ID, this.urlPath);

    return this.apiService.post(`${this.urlPath}/${teamId}`, params, false, context);
  }
}
