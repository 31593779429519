@if (!isProfile) {
  <button mat-flat-button
          [color]="getSocialEmail(socialProvider.Google) ? 'warn' : 'accent'"
          type="button"
          [disabled]="isDisabled"
          class="mb-3 py-4 mx-2"
          (click)="clickAction(socialProvider.Google)">
    <ng-container [ngTemplateOutlet]="(user | json) === '{}' ? login : userExist"
                  [ngTemplateOutletContext]="{provider: socialProvider.Google}"></ng-container>
  </button>
  <button mat-flat-button
          [color]="getSocialEmail(socialProvider.Facebook) ? 'warn' : 'accent'"
          type="button"
          [disabled]="isDisabled"
          class="mb-3 py-4 mx-2"
          (click)="clickAction(socialProvider.Facebook)">
    <ng-container [ngTemplateOutlet]="(user | json) === '{}' ? login : userExist"
                  [ngTemplateOutletContext]="{provider: socialProvider.Facebook}"></ng-container>
  </button>
  <button mat-flat-button
          [color]="getSocialEmail(socialProvider.Github) ? 'warn' : 'accent'"
          type="button"
          [disabled]="isDisabled"
          class="mb-3 py-4 mx-2"
          (click)="clickAction(socialProvider.Github)">
    <ng-container [ngTemplateOutlet]="(user | json) === '{}' ? login : userExist"
                  [ngTemplateOutletContext]="{provider: socialProvider.Github}"></ng-container>
  </button>
}
@if (isProfile) {
  <ng-container [ngTemplateOutlet]="providerButtonBlock"
                [ngTemplateOutletContext]="{provider: socialProvider.Github}"></ng-container>

  <ng-container [ngTemplateOutlet]="providerButtonBlock"
                [ngTemplateOutletContext]="{provider: socialProvider.Google}"></ng-container>

  <ng-container [ngTemplateOutlet]="providerButtonBlock"
                [ngTemplateOutletContext]="{provider: socialProvider.Facebook}"></ng-container>
}

<ng-template #login let-provider="provider">
  {{ 'actions.social.with' | translate: {provider: provider | titlecase} }}
</ng-template>
<ng-template #userExist let-provider="provider">
  @if (getSocialEmail(provider)) {
    <span>
      {{ 'actions.social.remove' | translate: {provider: provider | titlecase} }}
    </span>
  } @else {
    <span>
      {{ 'actions.social.connect' | translate: {provider: provider | titlecase} }}
    </span>
  }
</ng-template>

<ng-template #providerButtonBlock let-provider="provider">
  <div class="social-button">
    <div class="social-button__title">
      <div class="social-button__title__icon">
        <mat-icon [svgIcon]="provider"></mat-icon>
      </div>
      <div class="social-button__title__text">
        {{ (getSocialEmail(provider) ? 'actions.socialProfile.connected' : 'actions.socialProfile.notConnected') | translate: {provider: provider | titlecase} }}
        <div class="social-button__title__text__footer">{{ getSocialEmail(provider) }}</div>
      </div>
    </div>
    <div class="social-button__connect-button">
      <ng-container [ngTemplateOutlet]="providerButton"
                    [ngTemplateOutletContext]="{provider}"></ng-container>
    </div>
  </div>
</ng-template>

<ng-template #providerButton let-provider="provider">
  <div class="social-button__connect-button">
    <button mat-stroked-button type="button" class="ms-3" (click)="clickAction(provider)">
      {{ (getSocialEmail(provider) ? 'actions.socialProfile.remove' : 'actions.socialProfile.connect') | translate }}
      <mat-icon>{{ getSocialEmail(provider) ? 'link_off' : 'link' }}</mat-icon>
    </button>
  </div>
</ng-template>
