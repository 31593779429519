<div class="p-5">
  @if (loading) {
    <div>
      <div class="d-flex justify-content-center">
        <mat-spinner></mat-spinner>
      </div>
    </div>
  }

  <p id="onboarding-header" class="mat-h2">{{ 'pages.onboarding' | translate }}</p>

  @if (!loading) {
    <form [formGroup]="form" class="col-lg-6 offset-lg-3 ">
      <fieldset [disabled]="isSubmitting" aria-labelledby="onboarding-header">
        <div class="row justify-content-center">
          <div class="mb-3 d-flex justify-content-center">
            <div class="avatar-container">
              <input class="d-none" [accept]="['.jpg', '.png', '.bmp', 'jpeg']"
                     type="file"
                     #fileInput
                     (change)="uploadAvatar(fileInput.files)">
              <app-avatar [user]="user" [preview]="preview" [profile]="true" (click)="fileInput.click()"></app-avatar>
              <div class="avatar-upload-icon" (click)="fileInput.click()">+</div>
            </div>
          </div>
          <app-input formControlName="first_name"
                     [config]="{ id: 'first_name', label: 'firstName', class: 'mb-3 w-100' }"
                     [errors]="form.get('first_name').errors"
                     [required]="true"
          ></app-input>
          <app-input formControlName="last_name"
                     [config]="{ id: 'last_name', label: 'lastName', class: 'mb-3 w-100' }"
                     [errors]="form.get('last_name').errors"
                     [required]="true"
          ></app-input>
          @if (!user.invited_at && user.role !== UserRoles.ADMIN) {
            <app-input formControlName="team_name"
                       [config]="{ id: 'team_name', label: 'teamName', class: 'mb-3 w-100' }"
                       [errors]="form.get('team_name').errors"
                       [required]="true"
            ></app-input>
          }
          <div class="mb-3">
            <mat-form-field appearance="fill" class="w-100">
              <mat-label>{{ 'form.dateOfBirth.label' | translate }}</mat-label>
              <input matInput [max]="maxDateOfBirth" [matDatepicker]="picker" formControlName="date_of_birth">
              <mat-hint>{{ 'form.dateOfBirth.hint' | translate }}</mat-hint>
              <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
              <mat-error>{{ 'validations.required' | translate }}</mat-error>
            </mat-form-field>
          </div>
          <div class="d-flex w-100 justify-content-end">
            <button mat-flat-button color="primary" type="submit" class="submit" (click)="submitForm()"
                    [disabled]="isSubmitting || !form.valid">
              {{ 'actions.save' | translate }}
            </button>
          </div>
        </div>
      </fieldset>
    </form>
  }
</div>
<ng-template #progressBar>
  <div class="d-flex align-items-center">
    <span>0%</span>
    @if ((upload$ | async); as upload) {
      <mat-progress-bar
        [mode]="upload.state === 'PENDING' ? 'buffer' : 'determinate'"
        [value]="upload.progress"
        class="mx-2"
      >
      </mat-progress-bar>
    }
    <span>100%</span>
  </div>
</ng-template>
