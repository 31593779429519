import { Component } from '@angular/core';

import { ThemeService } from '../../../core/services/theme.service';

@Component({
  selector: 'app-theme-toggle',
  templateUrl: './theme-toggle.component.html',
})
export class ThemeToggleComponent {
  constructor(private themeService: ThemeService) {
  }

  public toggleTheme(): void {
    this.themeService.toggleTheme();
  }

  public isDarkTheme(): boolean {
    return document.body.classList.contains('dark-theme');
  }
}
