import {
  HttpContext,
  HttpParams,
} from '@angular/common/http';
import { Injectable } from '@angular/core';

import {
  forkJoin,
  Observable,
} from 'rxjs';
import {
  map,
  switchMap,
} from 'rxjs/operators';

import { ApiService } from './api.service';
import { BaseService } from './base.service';
import { TeamsService } from './teams.service';

import { REQUEST_ID } from '../../shared';
import {
  BaseResponse,
  PaginationContent,
  Project,
  ProjectMember,
  TeamMember,
} from '../models';

@Injectable({
  providedIn: 'root',
})
export class ProjectsService extends BaseService{
  protected readonly urlPath: string = '/projects';
  constructor(
    private apiService: ApiService,
    private teamService: TeamsService,

  ) {
    super();
  }

  getProject(id: number | string) {
    return this.apiService.get<Project>(`${this.urlPath}/${id}`).pipe(map(response => response.data));
  }

  fetchProjects(params?: HttpParams) {
    if (!params) {
      params = new HttpParams();
    }
    return this.apiService.get<PaginationContent<Project>>(this.urlPath, params).pipe(map(response => response.data));
  }

  createProject(params: object){
    const context = new HttpContext().set(REQUEST_ID, `${this.urlPath}/create`);

    return this.apiService.post<Project>(this.urlPath, params, false, context).pipe(map(
      response => {
        if (response.data) {
          return response.data;
        }
      }
    ));
  }

  public updateProject(id: number, params: object, upload = false): Observable<Project> {
    const context = new HttpContext().set(REQUEST_ID, this.urlPath);

    return this.apiService.patch<Project>(this.urlPath + `/${id}`, params, false, context)
      .pipe(map(
        response => {
          if (response.data) {
            return response.data;
          }
        }
      ));
  }

  public deleteProject(id: number | string): Observable<BaseResponse<null>> {
    return this.apiService.delete<null>(this.urlPath + `/${id}`);
  }

  getUserTeams(memberId: number) {
    return this.apiService.get<{ list: TeamMember[] }>('/team_members').pipe(
      switchMap(response => {
        const teamRequests = response.data.list
          .filter(teamMember => teamMember.member_id === memberId)
          .map(teamMember => this.teamService.getTeam(teamMember.team_id));

        return forkJoin(teamRequests).pipe(
          map(teams => teams.map(team => ({ teamId: team.id, teamName: team.name })))
        );
      })
    );
  }

  getProjectMembers(id: number | string, params?: HttpParams) {
    if (!params) {
      params = new HttpParams();
    }

    return this.apiService.get<PaginationContent<ProjectMember>>(`${this.urlPath}/${id}/members`, params)
      .pipe(
        map(response => response.data)
      );
  };

  assignTeamMember(projectId: number, memberId: number): Observable<BaseResponse<void>> {
    const context = new HttpContext().set(REQUEST_ID, this.urlPath);
    return this.apiService.post(`${this.urlPath}/${projectId}/assign`, {
      team_member_id: memberId,
    }, false, context);
  }

  unAssignTeamMember(projectId: number, memberId: number): Observable<BaseResponse<void>> {
    const context = new HttpContext().set(REQUEST_ID, this.urlPath);
    return this.apiService.post(`${this.urlPath}/${projectId}/unassign`, {
      team_member_id: memberId,
    }, false, context);
  }

  getProjectWithParams(projectId: number, params: { [key: string]: string }): Observable<Project> {
    let queryParams = new HttpParams();
    for (const key in params) {
      if (params.hasOwnProperty(key)) {
        queryParams = queryParams.set(key, params[key]);
      }
    }
    const url = `${this.urlPath}/${projectId}`;
    return this.apiService.get<Project>(url, queryParams)
        .pipe(map(response => response.data));
  }
}
