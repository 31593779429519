import { HttpParams } from '@angular/common/http';

import { HelperPrepareParamsService } from '../services';

export const PageSizeOptions = [25, 50, 100, 200];

export interface PaginationRequest extends IAjaxDataRequest {
  filter: Record<string, any> | undefined;
}

interface IAjaxDataRequest {
  page: number;
  per_page: number;
  data: any;
  order: Array<AjaxDataRequestSort>;
}

export enum SortDirection {
  ASC = 'asc',
  DESC = 'desc',
  NONE = ''
}

export interface AjaxDataRequestSort {
  columnName: string;
  direction: SortDirection | string;
}

export class AjaxDataRequest implements IAjaxDataRequest {
  get page(): number {
    return this._page;
  }

  set page(value: number) {
    if (this.page === value) {
      return;
    }
    this._page = value;
  }

  get per_page(): number {
    return this._per_page;
  }

  set per_page(value: number) {
    if (this.per_page === value) {
      return;
    }
    this._per_page = value;
  }

  data: any;
  order: Array<AjaxDataRequestSort>;
  filter?: Record<string, any>;
  private _page: number;
  private _per_page: number;
  params: HttpParams;
  dateFields: string[] = [];
  onlyDate = false;

  constructor() {
    this.data = null;
    this.order = [];
    this.filter = {};
    this._page = 1;
    this._per_page = 25;
    this.params = new HttpParams();
  }

  public addSort(columnName: string, direction: string) {
    const sort = this.order.find(x => x.columnName === columnName);
    if (direction === SortDirection.NONE){
      this.removeSort(columnName);
      return;
    }
    if (sort) {
      if (sort.direction !== direction) {
        sort.direction = direction;
      }
    } else {
      this.order.unshift({ columnName: columnName, direction: direction });
    }
  }

  public removeSort(columnName: string) {
    this.order = this.order.filter(col => col.columnName !== columnName);
  }

  private parseOrder() {
    const sortOrder = [];
    for (const order of this.order) {
      sortOrder.push((order.direction === SortDirection.DESC ? '-' : '') + order.columnName);
    }
    return sortOrder;
  }

  public getHttpParams(): HttpParams {
    let params = new HttpParams().set('page', this.page);
    if (this.per_page) {
      params = params.append('per_page', this.per_page);
    }
    if (this.order.length) {
      this.parseOrder().forEach( param => {
        params = params.append('order[]', param);
      });
    }
    if (this.filter) {
      params = params.appendAll(HelperPrepareParamsService.prepareParams(this.filter, this.dateFields, this.onlyDate));
    }
    return params;
  }
}
