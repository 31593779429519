import {
  AfterViewInit,
  Component,
  ViewChild,
} from '@angular/core';
import {
  MatPaginator,
  PageEvent,
} from '@angular/material/paginator';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import {
  MatSort,
  Sort,
} from '@angular/material/sort';

import { Observable } from 'rxjs';

import {
  AjaxDataRequest,
  PageSizeOptions,
  PaginationContent,
} from '../../../core';
import { AjaxDataSource } from '../../../core/services/ajax.datasource';

@Component({
  selector: 'app-datatable',
  template: '',
})
export abstract class DataTableComponent implements AfterViewInit {
  abstract datasource: AjaxDataSource<any>;
  abstract filterStorage: string;
  abstract filterService: string;
  abstract defaultFilters: Record<string, any>;
  ajaxParams = new AjaxDataRequest();
  pageSizeOptions = PageSizeOptions;

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) pagination: MatPaginator;
  @ViewChild(MatProgressSpinner) spinner: MatProgressSpinner;

  ngAfterViewInit() {
    if(this.sort.active){
      this.ajaxParams.addSort(this.sort.active, this.sort.direction);
    }
    this.sort.sortChange.subscribe({
      next: (event) => {
        this.sortChange(event);
      },
    });
    this.pagination.page.subscribe({
      next: (event) => {
        this.pageChange(event);
      },
    });
    this.reload();
  }

  abstract reload();

  updateDataSource<T>(observable: Observable<PaginationContent<T>>) {
    if (!this.datasource) {
      this.datasource = new AjaxDataSource<T>([]);
    }
    this.datasource.load(observable);
  }

  sortChange($event: Sort) {
    this.ajaxParams.page = 1;
    this.ajaxParams.addSort($event.active, $event.direction);
    this.pagination.pageIndex = 0;
    this.reload();
  }

  pageChange($event: PageEvent) {
    let paginationChange = false;
    if (this.ajaxParams.page !== ($event.pageIndex + 1)) {
      this.ajaxParams.page = $event.pageIndex + 1;
      paginationChange = true;
    }
    if (this.ajaxParams.per_page !== $event.pageSize) {
      this.ajaxParams.per_page = $event.pageSize;
      this.ajaxParams.page = 1;
      paginationChange = true;
    }
    if (paginationChange) {
      this.reload();
    }
  }

  initFilters() {
    let filters = this.defaultFilters;
    if (this.filterService) {
      filters = { ...filters, ...this[this.filterService].getFilter(this.filterStorage) };
    }
    this.ajaxParams.filter = filters;
  }

  applyFilter() {
    this.ajaxParams.page = 1;
    this.pagination.pageIndex = 0;
    if (this.filterService) {
      this[this.filterService].setFilter(this.ajaxParams.filter, this.filterStorage);
    }
    this.reload();
  }

  resetFilters() {
    this.ajaxParams.filter = Object.assign({}, this.defaultFilters);
  }
}
