import {
  Component,
  OnInit,
} from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import {
  ActivatedRoute,
  Router,
} from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import {combineLatest, of} from 'rxjs';
import { switchMap } from 'rxjs/operators';

import {
  AppService,
  HelperPrepareParamsService,
  Project,
  PaginationContent,
  ProjectsService,
  Team,
  TeamsService,
  User,
  UserService,
} from '../../../../core';

@Component({
  selector: 'app-admin-project-edit',
  templateUrl: './edit.component.html',
  styleUrl: './edit.component.scss',
})
export class ProjectEditComponent implements OnInit{
  editProject: FormGroup;
  user: User;
  teams: any[] = [];
  defaultRoleName: string;

  constructor(
    private route: ActivatedRoute,
    private appService: AppService,
    private fb: FormBuilder,
    private router: Router,
    private userService: UserService,
    private projectsService: ProjectsService,
    private teamsService: TeamsService,
    private translate: TranslateService
  ) {
  }

  ngOnInit() {
    this.appService.title = 'projects.edit.title';
    const projectId = +this.route.snapshot.paramMap.get('id');
    const params = { 'with_permissions': 'true' };

    this.initializeForm();

    combineLatest([
      this.projectsService.getProjectWithParams(projectId, params),
      this.userService.currentUser.pipe(
        switchMap(user => {
          this.user = user;
          return user ? this.teamsService.fetchTeams() : of({ list: [] } as PaginationContent<Team>);
        })
      )
    ]).subscribe(([project, result]) => {
      if (project) {
        this.populateForm(project);
      }
      this.teams = result.list.map(team => ({ teamId: team.id, teamName: team.name }));
    });

    this.translate.get('form.defaultRoleName').subscribe(translatedRoleName => {
      this.defaultRoleName = translatedRoleName;
      this.addDefaultRole();
    });
  }

  initializeForm() {
    this.editProject = this.fb.group({
      name: ['', [Validators.required, Validators.minLength(4), Validators.maxLength(255)]],
      key: ['', [Validators.required, Validators.minLength(4), Validators.maxLength(255)]],
      team_id: ['', [Validators.required, Validators.pattern('^[0-9]+$')]],
      roles: this.fb.array([]),
    });
  }

  populateForm(project: Project) {
    this.editProject.patchValue({
      name: project.name,
      key: project.key,
      team_id: project.team_id,
    });

    this.roles.clear();

    const permissions = JSON.parse(project.permissions || '{}');
    Object.entries(permissions).forEach(([roleName, access], index) => {
      const isFirstRole = index === 0;
      this.roles.push(this.fb.group({
        name: [{ value: roleName, disabled: isFirstRole }],
        access: [access],
      }));
    });
  }

  get roles(): FormArray {
    return this.editProject.get('roles') as FormArray;
  }

  addDefaultRole() {
    this.roles.insert(0, this.fb.group({
      name: [{ value: this.defaultRoleName, disabled: true }],
      access: ['accessOwn'],
    }));
  }

  addRole() {
    this.roles.push(this.fb.group({
      name: ['', Validators.required],
      access: ['accessOwn'],
    }));
  }

  removeRole(index: number) {
    if (this.roles.length > 1) {
      this.roles.removeAt(index);
    }
  }

  onSubmit() {
    if (this.editProject.valid) {
      const projectId = +this.route.snapshot.paramMap.get('id');
      const formValue = this.editProject.getRawValue();

      const permissions = formValue.roles.reduce((
        acc: { [roleName: string]: string  },
        role: { name: string; access: string }) => {
        if (role.name) {
          acc[role.name] = role.access;
        }
        return acc;
      }, {});

      const { roles, ...rest } = this.editProject.value;
      const projectEditParams = {
        ...HelperPrepareParamsService.prepareParams(rest),
        permissions: permissions,
      };

      this.projectsService.updateProject(projectId, projectEditParams).subscribe({
        next: project => {
          this.router.navigateByUrl('/projects/' + project.id);
        },
      });
    }
  }
}
