import {
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

import { TranslateService } from '@ngx-translate/core';

import {
  SocialProvider,
  SocialService,
  ToastsService,
  User,
  UserService,
} from '../../../core';

@Component({
  selector: 'app-social-buttons',
  templateUrl: './social-buttons.component.html',
  styleUrls: ['../user-profile/user-profile.component.scss'],
})
export class SocialButtonsComponent {
  @Input() isDisabled: boolean;
  @Input() isProfile: boolean;
  @Output() isDisabledChange = new EventEmitter<boolean>;

  socialProvider = SocialProvider;
  user?: User;

  constructor(
    private readonly socialService: SocialService,
    private readonly userService: UserService,
    private readonly toastsService: ToastsService,
    private readonly translateService: TranslateService,
  ) {
    this.userService.currentUser.subscribe({
      next: (user: User) => this.user = user,
    });
  }

  clickAction(provider: SocialProvider) {
    if (this.getSocialEmail(provider)) {
      this.deleteSocial(provider);
    } else {
      this.loginWithSocial(provider);
    }
  }

  loginWithSocial(provider: SocialProvider) {
    this.isDisabledChange.emit(true);
    this.socialService.getSocialLink(provider).subscribe({
      next: (response) => {
        this.isDisabledChange.emit(false);
        document.location.assign(response.link);
      },
      error: () => {
        this.isDisabledChange.emit(false);
      },
    });
  }

  deleteSocial(provider: SocialProvider) {
    this.isDisabledChange.emit(true);
    this.socialService.deleteSocial(provider).subscribe({
      next: () => {
        this.userService.profile().subscribe({
          next: () => {
            this.isDisabledChange.emit(false);
            this.toastsService.add(this.translateService.instant('messages.removedSuccessfully'));
          },
          error: () => {
            this.isDisabledChange.emit(false);
          },
        });
      },
      error: () => {
        this.isDisabledChange.emit(false);
        this.toastsService.add(this.translateService.instant('messages.somethingWentWrong'));
      },
    });
  }

  getSocialEmail(provider: SocialProvider): string {
    const email = this.user?.providers?.find(s => s.provider_name === provider);
    return email?.email ?? null;
  }
}
