@if (isLoading) {
  <div class="spinner">
    <mat-progress-spinner color="primary" mode="indeterminate" diameter="50"></mat-progress-spinner>
  </div>
}
<div class="px-12 justify-content-center flex-form">
  <form [formGroup]="createTicket" (ngSubmit)="onSubmit()">
    <div class="flex-center-form flex-column">
      <mat-form-field appearance="fill" class="w-60">
        <mat-label>{{ 'tickets.form.title' | translate }}</mat-label>
        <input matInput formControlName="title">
        <mat-error>
          <app-validation-error-render
            label="title"
            [errors]="createTicket.controls.title.errors">
          </app-validation-error-render>
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="fill" class="w-60">
        <mat-label>{{ 'tickets.form.content' | translate }}</mat-label>
        <textarea matInput formControlName="content"></textarea>
        <mat-error>
          <app-validation-error-render
            label="content"
            [errors]="createTicket.controls.content.errors">
          </app-validation-error-render>
        </mat-error>
      </mat-form-field>

      <div formGroupName="custom_fields">
        <mat-form-field appearance="fill" class="w-60">
          <mat-label>{{ 'tickets.form.custom_fields.type' | translate }}</mat-label>
          <mat-select formControlName="type">
            @for (type of typeOptions; track type) {
              <mat-option [value]="type">{{ type }}</mat-option>
            }
          </mat-select>
          <mat-error>
            <app-validation-error-render
              label="customFields.type"
              [errors]="createTicket.get('custom_fields').get('type').errors">
            </app-validation-error-render>
          </mat-error>
        </mat-form-field>

        @if (showLevelField) {
          <mat-form-field appearance="fill" class="w-60">
            <mat-label>{{ 'tickets.form.custom_fields.level' | translate }}</mat-label>
            <mat-select formControlName="level">
              @for (level of levelOptions; track level) {
                <mat-option [value]="level">{{ level }}</mat-option>
              }
            </mat-select>
            <mat-error>
              <app-validation-error-render
                label="customFields.level"
                [errors]="createTicket.get('custom_fields').get('level').errors">
              </app-validation-error-render>
            </mat-error>
          </mat-form-field>
        }
        <mat-form-field appearance="fill" class="w-60">
          <mat-label>{{ 'tickets.form.custom_fields.priority' | translate }}</mat-label>
          <mat-select formControlName="priority">
            @for (priority of priorityOptions; track priority) {
              <mat-option [value]="priority">{{ priority }}</mat-option>
            }
          </mat-select>
          <mat-error>
            <app-validation-error-render
              label="customFields.priority"
              [errors]="createTicket.get('custom_fields').get('priority').errors">
            </app-validation-error-render>
          </mat-error>
        </mat-form-field>
        <mat-form-field appearance="fill" class="w-60">
          <mat-label>{{ 'tickets.form.custom_fields.status' | translate }}</mat-label>
          <mat-select formControlName="status">
            @for (status of statusOptions; track status) {
              <mat-option [value]="status">{{ status }}</mat-option>
            }
          </mat-select>
          <mat-error>
            <app-validation-error-render
              label="customFields.status"
              [errors]="createTicket.get('custom_fields').get('status').errors">
            </app-validation-error-render>
          </mat-error>
        </mat-form-field>
      </div>
      <mat-form-field appearance="fill" class="w-60">
        <mat-label>{{ 'tickets.form.page_url' | translate }}</mat-label>
        <input matInput formControlName="page_url">
        <mat-error>
          <app-validation-error-render
            label="page_url"
            [errors]="createTicket.controls.page_url.errors">
          </app-validation-error-render>
        </mat-error>
      </mat-form-field>
    </div>
    <button mat-raised-button color="primary" type="submit" [disabled]="!createTicket.valid || isLoading">
      {{ 'actions.submit' | translate }}
    </button>
  </form>
</div>

