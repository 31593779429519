import {
  HttpContext,
  HttpParams,
} from '@angular/common/http';
import { Injectable } from '@angular/core';

import { map } from 'rxjs/operators';

import { ApiService } from './api.service';
import { BaseService } from './base.service';

import { REQUEST_ID } from '../../shared';
import {
  PaginationContent,
  Team,
} from '../models';

@Injectable({
  providedIn: 'root',
})
export class TeamsService extends BaseService {
  protected readonly urlPath: string = '/teams';

  constructor(
    private apiService: ApiService,
  ) {
    super();
  }

  getTeam(id: number | string) {
    return this.apiService.get<Team>(`${this.urlPath}/${id}`).pipe(map(response => response.data));
  }

  fetchTeams(params?: HttpParams) {
    if (!params) {
      params = new HttpParams();
    }

    return this.apiService.get<PaginationContent<Team>>(this.urlPath, params).pipe(map(response => response.data));
  }

  createTeam(params: object) {
    const context = new HttpContext().set(REQUEST_ID, `${this.urlPath}/create`);

    return this.apiService.post<Team>(this.urlPath, params, false, context).pipe(map(
      response => {
        if (response.data) {
          return response.data;
        }
      }
    ));
  }
}
