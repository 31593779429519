<div class="row w-100 mb-3">
  @if (!userId) {
    <div class="col-12 col-md-8 d-flex align-items-center">
      <mat-form-field appearance="fill" class="no-hint-input">
        <mat-label>{{ 'filters.actionLogs.hint' | translate }}</mat-label>
        <mat-select [(ngModel)]="ajaxParams.filter.action" (selectionChange)="applyFilter()">
          @for (action of actions; track action) {
            <mat-option [value]="action">
              {{ 'actionLogs.' + action | translate:datasource }}
            </mat-option>
          }
        </mat-select>
      </mat-form-field>
      @if (isAdmin) {
        <mat-label class="mx-2">{{ 'filters.actionLogs.user_only' | translate }}</mat-label>
        <mat-checkbox [(ngModel)]="ajaxParams.filter.user_only" (change)="applyFilter()">
        </mat-checkbox>
      }
      <button mat-flat-button color="primary" type="button" (click)="clearFilters()" class="ms-3">
        {{ 'actions.clear' | translate }}
        <mat-icon>close</mat-icon>
      </button>
    </div>
  }
</div>

@if (datasource.loading$ | async) {
  <div class="spinner">
    <mat-progress-spinner color="primary" mode="indeterminate" diameter="50">
    </mat-progress-spinner>
  </div>
}

<div class="table-container">
  <table mat-table #sort [dataSource]="datasource" aria-label="action-logs" matSort matSortActive="created_at"
         matSortDirection="desc">
    <ng-container matColumnDef="user">
      <th class="w-15 text-nowrap" scope="row" mat-header-cell *matHeaderCellDef disabled>
        {{ 'form.firstName.label' | translate }},
        {{ 'form.lastName.label' | translate }}
      </th>
      <td mat-cell *matCellDef="let actionLog">
        @if (actionLog.user) {
          <p routerLink="/users/{{actionLog.user.id}}" class="cursor-pointer">
            {{ actionLog.user?.first_name }}
            {{ actionLog.user?.last_name }}
          </p>
        } @else {
          {{ 'form.noUser.label' | translate }}
        }
      </td>
    </ng-container>
    <ng-container matColumnDef="action">
      <th class="w-15" scope="row" mat-header-cell mat-sort-header *matHeaderCellDef>
        {{ 'form.action.label' | translate }}
      </th>
      <td mat-cell *matCellDef="let actionLog"> {{ 'actionLogs.' + actionLog.action | translate }}</td>
    </ng-container>
    <ng-container matColumnDef="ip">
      <th class="w-15 text-nowrap" scope="row" mat-header-cell mat-sort-header *matHeaderCellDef>
        {{ 'form.ip.label' | translate }}
      </th>
      <td mat-cell *matCellDef="let actionLog"> {{ actionLog.ip }}</td>
    </ng-container>
    <ng-container matColumnDef="data">
      <th class="w-40" scope="row" mat-header-cell *matHeaderCellDef>
        {{ 'form.data.label' | translate }}
      </th>
      <td mat-cell *matCellDef="let actionLog">
        <pre>{{ actionLog.data?.length !== 0 ? (actionLog.data | json) : '' }}</pre>
      </td>
    </ng-container>
    <ng-container matColumnDef="created_at">
      <th class="w-15" scope="row" mat-header-cell mat-sort-header arrowPosition="before" *matHeaderCellDef>
        {{ 'form.created.label' | translate }}
      </th>
      <td mat-cell *matCellDef="let actionLog" class="text-right">
        {{ actionLog.created_at | localizedDate: 'short' }}
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="userId ? userViewDisplayedColumns : displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: userId ? userViewDisplayedColumns : displayedColumns;"></tr>
  </table>
</div>
<mat-paginator #pagination
               [pageSizeOptions]="pageSizeOptions"
               [length]="datasource.total$ | async">
</mat-paginator>

<ng-template #noUser>
  {{ 'form.noUser.label' | translate }}
</ng-template>
