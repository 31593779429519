import { User } from './user.model';

export enum ActionType {
  Login = 'login',
  ConfirmedAccount = 'confirmed_account',
  Logout = 'logout',
  Registered = 'registered',
  ResetPasswordInit = 'reset_password_init',
  ResetPassword = 'reset_password',
  SentConfirmationEmail = 'sent_confirmation_email',
  UpdatedProfile = 'updated_profile',
  HandlerAuthSocialite = 'handler_auth_socialite',
  CreateUser = 'create_user',
  DeletedUser = 'deleted_user',
  UpdatedUser = 'updated_user',
  UploadFile = 'upload_file',
  DeletedUnusedFiles = 'deleted_unused_files',
  UpdatedAppSetting = 'updated_app_setting',
}

export interface ActionLog {
  id: number;
  user_id?: number;
  action: ActionType;
  foreign_id: number;
  foreign_table: string;
  data?: object;
  ip: string;
  user_agent: string;
  created_at: Date | string;
  updated_at: Date | string;

  user?: User;
}
