import { HttpParams } from '@angular/common/http';
import {
  Component,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  MatPaginator,
  PageEvent,
} from '@angular/material/paginator';

import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';

import {
  Comment,
  CommentsService,
  PaginationContent,
  TeamMemberRoles,
  ToastsService,
  User,
  UserRoles,
  UserService,
} from '../../../core';

@Component({
  selector: 'app-comments',
  templateUrl: './comments.component.html',
  styleUrl: 'comments.component.scss',
})
export class CommentsComponent implements OnInit {
  readonly pageSizeOptions = [5, 10, 20];

  user: User;
  isLoading = true;
  editedComment: Comment;
  paginatedComments: PaginationContent<Comment> = {
    list: [],
    page: 1,
    per_page: 5,
    total: 0,
    total_pages: 0,
  };

  @Input() teamId: number;
  @Input() ticketId: number;

  @ViewChild(MatPaginator) paginator!: MatPaginator;

  constructor(
    private commentsService: CommentsService,
    private toastsService: ToastsService,
    private userService: UserService,
    private translate: TranslateService
  ) {
  }

  ngOnInit(): void {
    this.fetchComments();

    this.userService.currentUser.pipe(take(1)).subscribe({
      next: (user: User): void => {
        this.user = user;
      },
      error: (err: string): void => {
        this.toastsService.add(err);
      },
    });
  }

  fetchComments(): void {
    const params = new HttpParams()
      .set('ticket_id', this.ticketId)
      .set('page', this.paginatedComments.page)
      .set('per_page', this.paginatedComments.per_page);

    this.commentsService.fetchComments(params).subscribe({
      next: (data: PaginationContent<Comment>): void => {
        this.paginatedComments = data;
        this.isLoading = false;
      },
      error: (err: string): void => {
        this.toastsService.add(err);
        this.isLoading = false;
      },
    });
  }

  onPageChange(event: PageEvent): void {
    this.paginatedComments.page = event.pageIndex + 1;
    this.paginatedComments.per_page = event.pageSize;
    this.fetchComments();
  }

  getAvatar(comment: Comment): string {
    const defaultAvatar = '../../../../assets/icons/person.svg';
    return comment.creator?.avatar ? comment.creator.avatar.presets['250x250'] : defaultAvatar;
  }

  save(comment: string): void {
    this.isLoading = true;
    if (this.editedComment.id) {
      this.commentsService.updateComment(this.editedComment.id, { 'content': comment }).subscribe({
        next: (response) => {
          const commentIndex = this.paginatedComments.list.findIndex(item => item.id === response.id);
          if (commentIndex !== -1) {
            this.paginatedComments.list[commentIndex] = { ...response };
          }
        },
        error: (err: string): void => {
          this.toastsService.add(err);
        },
        complete: () => {
          this.isLoading = false;
          this.editedComment = undefined;
        },
      });
    } else {
      this.commentsService.createComment({
        content: comment,
        ticket_id: this.ticketId,
      }).subscribe({
        next: (response) => {
          this.paginatedComments.list = [response, ...this.paginatedComments.list];
        },
        error: (err: string): void => {
          this.toastsService.add(err);
        },
        complete: () => {
          this.isLoading = false;
          this.editedComment = undefined;
        },
      });
    }
  }

  isEditAllowed(comment: Comment): boolean {
    if (this.user.role === UserRoles.ADMIN || comment.created_by === this.user.id) {
      return true;
    }
    if (this.teamId && this.user.members.length) {
      return !!this.user.members.filter((member) => (
        member.team_id === this.teamId
        && member.role === TeamMemberRoles.HEAD
      )).length;
    }
    return false;
  }

  onEdit(comment: Comment) {
    this.editedComment = comment;
  }

  clearEditedComment() {
    this.editedComment = undefined;
  }

  onCreate() {
    this.editedComment = {
      id: 0,
      ticket_id: this.ticketId,
      content: '',
      creator_name: `${this.user.first_name} ${this.user.last_name}`,
      creator: this.user,
    };
  }

  onDelete(id: number) {
    if (window.confirm(this.translate.instant('comments.delete_prompt'))) {
      this.isLoading = true;
      this.commentsService.deleteComment(id).subscribe({
        next: () => {
          this.paginatedComments.list = this.paginatedComments.list.filter(comment => comment.id !== id);
        },
        error: (err: string): void => {
          this.toastsService.add(err);
        },
        complete: () => {
          this.isLoading = false;
        },
      });
    }
  }
}
