import { Injectable } from '@angular/core';
import {
  CanActivate,
  Router,
} from '@angular/router';

import { TranslateService } from '@ngx-translate/core';
import {
  Observable,
  skipWhile,
} from 'rxjs';
import {
  map,
  take,
} from 'rxjs/operators';

import {
  AppAbility,
  ToastsService,
  User,
  UserRoles,
  UserService,
} from '../../core';

@Injectable()
export class OnboardingGuard implements CanActivate {
  constructor(
    private router: Router,
    private userService: UserService,
    private ability: AppAbility,
    private toastsService: ToastsService,
    private translateService: TranslateService,
  ) {
  }

  canActivate(): Observable<boolean> {
    return this.userService.currentUser.pipe(
      skipWhile((user: User) => !user.hasOwnProperty('id')),
      take(1),
      map((user: User) => {
        if (user.role === UserRoles.USER && !user.onboarded_at) {
          this.toastsService.add(this.translateService.instant('messages.onboardingError'));
          this.router.navigateByUrl('/onboarding');
          return false;
        }

        return true;
      })
    );
  }
}
