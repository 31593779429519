import {
  HttpContext,
  HttpParams,
} from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApiService } from './api.service';
import { BaseService } from './base.service';

import { REQUEST_ID } from '../../shared';
import {
  BaseResponse,
  PaginationContent,
  Ticket,
} from '../models';

@Injectable({
  providedIn: 'root',
})
export class TicketsService extends BaseService {
  protected readonly urlPath: string = '/support/tickets';

  public constructor(private apiService: ApiService) {
    super();
  }

  public getTicket(id: number | string): Observable<Ticket> {
    return this.apiService.get<Ticket>(this.urlPath + `/${id}`)
      .pipe(map(response => response.data));
  }

  public fetchTickets(params?: HttpParams): Observable<PaginationContent<Ticket>> {
    if (!params) {
      params = new HttpParams();
    }

    return this.apiService.get<PaginationContent<Ticket>>(this.urlPath, params)
      .pipe(map(response => response.data));
  }

  public createTicket(params: object): Observable<Ticket> {
    const context = new HttpContext().set(REQUEST_ID, this.urlPath);

    return this.apiService.post<Ticket>(this.urlPath, params, false, context).pipe(map(
      response => {
        if (response.data) {
          return response.data;
        }
      }
    ));
  }

  public updateTicket(id: number, params: object, upload = false): Observable<Ticket> {
    const context = new HttpContext().set(REQUEST_ID, this.urlPath);

    return this.apiService.patch<Ticket>(this.urlPath + `/${id}`, params, upload, context)
      .pipe(map(
        response => {
          if (response.data) {
            return response.data;
          }
        }
      ));
  }

  public deleteTicket(id: number | string): Observable<BaseResponse<null>> {
    return this.apiService.delete<null>(this.urlPath + `/${id}`);
  }

  public getTicketConfigs(): Observable<any> {
    return this.apiService.get<any>('/ticket/configs')
      .pipe(map(response => response.data));
  }
}
