import { Injectable } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import {
  fromEvent,
  merge,
  of,
  Subscription,
} from 'rxjs';
import { map } from 'rxjs/operators';

import { ToastsService } from './toasts.service';

@Injectable()
export class CheckNetworkService {
  networkStatus = false;
  networkStatus$: Subscription = Subscription.EMPTY;

  constructor(
    private toastsService: ToastsService,
    private translateService: TranslateService,
  ) {
  }

  checkNetworkStatus() {
    this.networkStatus = navigator.onLine;
    this.networkStatus$ = merge(
      of(null),
      fromEvent(window, 'online'),
      fromEvent(window, 'offline')
    )
      .pipe(map(() => navigator.onLine))
      .subscribe(status => {
        this.networkStatus = status;

        if (!this.networkStatus) {
          this.toastsService.add(this.translateService.instant('messages.offlineNow'), null, 0);
        } else {
          this.toastsService.dismiss();
        }
      });
  }

  unsubscribeNetworkStatus() {
    this.networkStatus$.unsubscribe();
  }
}
