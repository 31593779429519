import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
} from '@angular/router';

import { Observable } from 'rxjs';
import {
  map,
  take,
} from 'rxjs/operators';

import { UserService } from '../../core';

@Injectable({
  providedIn: 'root',
})
export class NoAuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private userService: UserService,
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> | boolean {
    if (route.data['clearauth']) {
      this.userService.purgeAuth();
      return true;
    }
    return this.userService.isAuthenticated.pipe(take(1), map(isAuth => {
      if (isAuth) {
        this.router.navigateByUrl('/');
        return false;
      }
      return !isAuth;
    }));
  }
}
