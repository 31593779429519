@if (isLoading) {
  <div class="spinner">
    <mat-progress-spinner color="primary" mode="indeterminate" diameter="50">
    </mat-progress-spinner>
  </div>
}

<div class="panel-wrapper">
  @if (!editedComment) {
    <div class="title">{{'comments.comments' | translate}}:</div>
    <button class="action-button" mat-flat-button color="primary" type="button" (click)="onCreate()">
      {{ 'actions.create' | translate }}
      <mat-icon>comment</mat-icon>
    </button>
  }
</div>

@if (!editedComment) {
  <div class="comments-container">
    @for (comment of paginatedComments.list; track comment.id; let i = $index) {
      <mat-card appearance="outlined">
        <div class="card-header">
          <div class="avatar-wrapper">
            <img class="img-avatar" [src]="getAvatar(comment)" alt="avatar">
            <mat-card-title>{{ comment.creator_name }}</mat-card-title>
          </div>
          @if (isEditAllowed(comment)) {
            <div class="action-buttons">
              <button class="edit-button"
                      mat-flat-button
                      color="primary"
                      type="button"
                      (click)="onEdit(comment)"
                      [disabled]="isLoading"
              >
                {{ 'comments.edit' | translate }}
                <mat-icon>edit</mat-icon>
              </button>
              <button class="error-button"
                      mat-flat-button
                      type="button"
                      (click)="onDelete(comment.id)"
                      [disabled]="isLoading"
              >
                {{ 'comments.delete' | translate }}
                <mat-icon>delete</mat-icon>
              </button>
            </div>
          }
        </div>
        <mat-card-content>{{ comment.content }}</mat-card-content>
        <mat-card-footer>
          <small>{{ comment.created_at | date:'short' }}</small>
        </mat-card-footer>
      </mat-card>
    }
    @if (paginatedComments.list.length <= 0) {
      <div class="placeholder">
        <p>{{ 'comments.placeholder' | translate }}</p>
      </div>
    }
  </div>
  <mat-paginator [length]="paginatedComments.total"
                 [pageSize]="paginatedComments.per_page"
                 [pageSizeOptions]="pageSizeOptions"
                 (page)="onPageChange($event)">
  </mat-paginator>
} @else {
  <app-create-comment
    [avatar]="getAvatar(editedComment)"
    [editedComment]="editedComment"
    [isLoading]="isLoading"
    (cancel)="clearEditedComment()"
    (save)="save($event)"
  ></app-create-comment>
}
