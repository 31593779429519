import { HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';

import {
  BehaviorSubject,
  Observable,
} from 'rxjs';
import { map } from 'rxjs/operators';

import { ApiService } from './api.service';

import { REQUEST_ID } from '../../shared';
import {
  RequestIds,
  User,
} from '../models';

@Injectable({
  providedIn: 'root',
})
export class OnboardingService {
  private currentUserSubject = new BehaviorSubject<User>({} as User);
  constructor(private apiService: ApiService) { }

  onboardUser(data: object, upload = false): Observable<any> {
    const context = new HttpContext().set(REQUEST_ID, RequestIds.USER_ONBOARDING);

    return this.apiService.post<User>('/auth/onboarding', data, upload, context)
      .pipe(
        map(response => {
          if (response.data) {
            this.currentUserSubject.next(response.data);
            return response.data;
          }
        })
      );
  }
}
