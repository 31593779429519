import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ThemeService {
  private readonly darkThemeClass: string = 'dark-theme';
  private readonly themeStorageKey: string = 'is-dark-theme';

  constructor() {
    const isDarkTheme: boolean = localStorage.getItem(this.themeStorageKey) === 'true';
    this.setTheme(isDarkTheme);
  }

  public toggleTheme(): void {
    this.setTheme(!this.isDarkTheme());
  }

  private setTheme(isDarkTheme: boolean): void {
    if (isDarkTheme) {
      document.body.classList.add(this.darkThemeClass);
    } else {
      document.body.classList.remove(this.darkThemeClass);
    }

    localStorage.setItem(this.themeStorageKey, String(isDarkTheme));
  }

  private isDarkTheme(): boolean {
    return document.body.classList.contains(this.darkThemeClass);
  }
}
