import { Injectable } from '@angular/core';
import {
  MatSnackBar,
  MatSnackBarRef,
  TextOnlySnackBar,
} from '@angular/material/snack-bar';

import { TranslateService } from '@ngx-translate/core';

interface ConfigInterface {
  duration?: number;
}

@Injectable({
  providedIn: 'root',
})
export class ToastsService {

  constructor(private snackBar: MatSnackBar, private translateService: TranslateService) {
  }

  add(message: string, action: string = null, timeout = 5000): MatSnackBarRef<TextOnlySnackBar> {
    const config: ConfigInterface = {};
    if (timeout) {
      config.duration = timeout;
    }
    return this.snackBar.open(message, action, config);
  }

  dismiss() {
    this.snackBar.dismiss();
  }
}
