<div class="page-container">
  <div>
    <div class="spinner" *ngIf="loading">
      <mat-progress-spinner color="primary" mode="indeterminate" diameter="50">
      </mat-progress-spinner>
    </div>
    <div class="table-container" *ngIf="!loading">
      <table mat-table [dataSource]="[ticket]" aria-label="tickets" matSort>
        <ng-container matColumnDef="title">
          <th class="w-25" scope="col" mat-header-cell *matHeaderCellDef>
            {{ 'tickets.show.title' | translate }}
          </th>
          <td mat-cell *matCellDef="let ticket"> {{ ticket.title }}</td>
        </ng-container>
        <ng-container matColumnDef="content">
          <th class="w-25" scope="col" mat-header-cell mat-sort-header *matHeaderCellDef>
            {{ 'tickets.show.content' | translate }}
          </th>
          <td mat-cell *matCellDef="let ticket"> {{ ticket.content }}</td>
        </ng-container>
        <ng-container matColumnDef="created_at">
          <th class="w-25" scope="col" mat-header-cell *matHeaderCellDef>
            {{ 'tickets.show.created_at' | translate }}
          </th>
          <td mat-cell *matCellDef="let ticket"> {{ ticket.created_at | date }}</td>
        </ng-container>
        <ng-container matColumnDef="actions">
          <th class="w-25" scope="col" mat-header-cell *matHeaderCellDef>
            {{ 'tickets.show.actions' | translate }}
          </th>
          <td mat-cell *matCellDef="let ticket" class="text-right">
            <div class="d-flex align-items-center justify-content-end">
              @if (isEditAllowed(ticket)) {
                <button mat-icon-button color="accent" type="button" routerLink="/tickets/{{ticket.id}}/edit">
                  <mat-icon class="ticket-edit-icon">edit</mat-icon>
                </button>
              }
            </div>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </div>
  </div>
  <app-comments
    [ticketId]="ticketId"
    [teamId]="Number(this.ticket?.source)"
  ></app-comments>
</div>
