import {
  HttpContext,
  HttpParams,
} from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApiService } from './api.service';
import { BaseService } from './base.service';

import { REQUEST_ID } from '../../shared';
import {
  PaginationContent,
  TeamMember,
} from '../models';

@Injectable({
  providedIn: 'root',
})
export class TeamMemberService extends BaseService {
  protected readonly urlPath: string = '/team_members';

  constructor(private apiService: ApiService) {
    super();
  }

  fetchTeamMembers(teamId?: number, params?: HttpParams): Observable<PaginationContent<TeamMember>> {
    if (!params) {
      params = new HttpParams();
    }

    if (teamId !== undefined) {
      params = params.append('team_id', teamId.toString());
    }

    return this.apiService.get<PaginationContent<TeamMember>>(this.urlPath, params).pipe(
      map(response => response.data)
    );
  }

  fetchAllowedToAssignTeamMembers(teamId: number, projectId: number, params?: HttpParams): Observable<PaginationContent<TeamMember>> {
    if (!params) {
      params = new HttpParams();
    }

    if (teamId !== undefined) {
      params = params.append('team_id', teamId.toString());
    }

    if (projectId !== undefined) {
      params = params.append('project_id', projectId.toString());
    }

    return this.apiService.get<PaginationContent<TeamMember>>('/allowed_team_members', params).pipe(
      map(response => response.data)
    );
  }

  createTeamMember(params: object) {
    const context = new HttpContext().set(REQUEST_ID, `${this.urlPath}/create`);

    return this.apiService.post<TeamMember>(this.urlPath, params, false, context)
      .pipe(map(
        response => {
          if (response.data) {
            return response.data;
          }
        }
      ));
  }

  deleteTeamMember(id: number) {
    return this.apiService.delete<null>(`${this.urlPath}/${id}`);
  }
}
