<h1 mat-dialog-title>{{'dialogs.changePassword' | translate}}</h1>
<div mat-dialog-content>
  <form [formGroup]="form">
  <app-input formControlName="old_password"
             [config]="{ id: 'old_password', label: 'oldPassword', type: hidePassword ? 'password' : 'text', class: 'w-100' }"
             [errors]="form.get('old_password').errors"
             [buttonConfig]="{icon: getPassIcon(hidePassword)}"
             [required]="true"
             (clickButton)="hidePassword = !hidePassword">
  </app-input>
  <app-input formControlName="new_password"
             [config]="{ id: 'new_password', label: 'newPassword', type: hideNewPassword ? 'password' : 'text', class: 'w-100' }"
             [errors]="form.get('new_password').errors"
             [buttonConfig]="{icon: getPassIcon(hideNewPassword)}"
             [required]="true"
             (clickButton)="hideNewPassword = !hideNewPassword">
  </app-input>
  </form>
</div>
<div mat-dialog-actions [align]="'end'">
  <button mat-button (click)="submit()">
    {{'actions.save' | translate}}
  </button>
  <button mat-button mat-dialog-close>
    {{'actions.cancel' | translate}}
  </button></div>
