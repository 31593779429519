import {
  Component,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';

import { TranslateService } from '@ngx-translate/core';
import {
  EMPTY,
  Observable,
  shareReplay,
} from 'rxjs';

import {
  ApiService,
  AppService,
  HelperPrepareParamsService,
  MediaService,
  OnboardingService,
  ToastsService,
  Upload,
  User,
  UserRoles,
  UserService,
} from '../../../core';

@Component({
  selector: 'app-onboarding',
  templateUrl: './onboarding.component.html',
  styleUrls: ['onboarding.component.scss'],
})
export class OnboardingComponent implements OnInit {
  @ViewChild('progressBar') progressBar: TemplateRef<any>;

  user: User;
  maxDateOfBirth: Date;
  form: FormGroup;
  avatarForm: FormGroup;
  loading = true;
  isSubmitting = false;
  upload$: Observable<Upload> = EMPTY;
  uploadedAvatar: File | null | undefined;
  preview: any;
  UserRoles = UserRoles;

  constructor(
    private readonly appService: AppService,
    private readonly fb: FormBuilder,
    private readonly userService: UserService,
    private readonly mediaService: MediaService,
    private apiService: ApiService,
    private _snackBar: MatSnackBar,
    private translateService: TranslateService,
    private toastsService: ToastsService,
    private readonly onboardingService: OnboardingService,
  ) {
    this.appService.title = 'pages.onboarding';

    this.maxDateOfBirth = new Date();
    this.form = this.fb.group({
      date_of_birth: ['', [Validators.required]],
      first_name: ['', [Validators.required]],
      last_name: ['', [Validators.required]],
      team_name: [''],
    });
    this.avatarForm = this.fb.group(
      {
        avatar: [''],
      }
    );
  }

  ngOnInit() {
    this.userService.currentUser.subscribe({
      next: user => {
        this.loading = false;
        this.user = user;

        this.form.patchValue(user);
        if (user.avatar) {
          this.form.patchValue({
            avatar: {
              id: this.user.avatar.id,
              delete_it: true,
            },
          });
        }
      },
      error: () => this.loading = false,
    });
  }

  uploadAvatar(files: FileList | null): void {
    if (!files) {
      return;
    }
    const file = files.item(0);
    if (file.size > this.apiService.ALLOWED_IMAGE_SIZE) {
      this.toastsService.add(this.translateService.instant('validations.avatar.size'));
      return;
    }
    if (!this.apiService.ALLOWED_IMAGE_TYPES.includes(file.type)) {
      this.toastsService.add(this.translateService.instant('validations.avatar.type'));
      return;
    }
    const reader = new FileReader();
    reader.onload = e => this.preview = e.target.result;
    reader.readAsDataURL(file);
    this.uploadedAvatar = file;
    this.preview = null;
  }

  sendAvatar() {
    this.upload$ = this.apiService.upload(this.uploadedAvatar).pipe(shareReplay());
    this.isSubmitting = true;
    this.openProgressBar();
    this.upload$
      .subscribe({
        next: file => {
          if (file.state === 'DONE') {
            this.dismissProgressBar();
            this.updateAvatar(file.data.id);
            this.isSubmitting = false;
          }
        },
        error: () => {
          this.isSubmitting = false;
        },
      });
  }

  updateAvatar(fileId) {
    this.isSubmitting = true;
    this.avatarForm.get('avatar').setValue({ id: fileId });
    this.userService.updateProfile(this.avatarForm.value).subscribe({
      next: user => {
        this.isSubmitting = false;
        this.user = user;
        this.userService.populate().then();
        this.userService.refreshToken().then();
      },
      error: () => {
        this.isSubmitting = false;
      },
    });
  }

  openProgressBar() {
    this._snackBar.openFromTemplate(this.progressBar);
  }

  dismissProgressBar() {
    setTimeout(() => {
      this._snackBar.dismiss();
    }, 1000);
  }

  submitForm() {
    if (!this.form.valid) {
      return;
    }
    this.isSubmitting = true;
    const onboardingData = HelperPrepareParamsService.prepareParams(this.form.value, ['date_of_birth'], true);
    this.onboardingService.onboardUser(onboardingData, true).subscribe({
      next: async () => {
        await this.userService.refreshToken();
        await this.userService.populate();
        this.isSubmitting = false;
        this.userService.openHomePage();
        if (this.uploadedAvatar) {
          this.sendAvatar();
        }
      },
      error: () => {
        this.isSubmitting = false;
      },
    });
  }
}
