<button mat-icon-button [matMenuTriggerFor]="notificationsList" (menuOpened)="getNotifications()" class="p-0">
  <mat-icon matBadge="{{statistic > 0 ? statistic : ''}}" matBadgePosition="after" matBadgeColor="accent"
            aria-hidden="false">
    notifications
  </mat-icon>
</button>

<mat-menu #notificationsList="matMenu">
  @if (notifications.length > 0) {
    <div>
      @for (notification of notifications; track notification.id) {
        <div class="bordered-bottom" mat-menu-item (click)="$event.stopPropagation()">
          <div class="d-flex align-items-center">
            <div class="w-100">
              <h3 class="mb-0">{{ notification.title }}</h3>
              <p class="mt-0" [matTooltip]="notification.text">
                {{ notification.text | truncate:25 }}
              </p>
            </div>
            @if (!notification.seen_at) {
              <button mat-icon-button (click)="markRead(notification.id)">
                <mat-icon>check</mat-icon>
              </button>
            }
          </div>
        </div>
      }
    </div>
  } @else {
    <div class="p-2">
      {{ 'messages.notificationsEmpty' | translate }}
    </div>
  }
</mat-menu>
