@if (avatarUrl || preview) {
  <img
    class="img-avatar"
    [src]="preview ?? avatarUrl"
    alt=""
    [ngStyle]="{width: width + 'px', height: width + 'px'}">
} @else {
  <div [ngStyle]="profile ? {width: width + 'px', height: width + 'px'}: {}">
    <mat-icon [class.profile-avatar]="profile">person</mat-icon>
  </div>
}

