import { Injectable } from '@angular/core';

import { map } from 'rxjs/operators';

import { ApiService } from './api.service';

import { Media } from '../models';

@Injectable()
export class MediaService {

  constructor(
    private apiService: ApiService,
  ) {
  }

  uploadFile(data: object | FormData) {
    return this.apiService.post<Media>('/file', data, true)
      .pipe(map(
        response => {
          return response.data;
        }
      ));
  }
}
