<h1 mat-dialog-title>
  {{title}}
</h1>

<div mat-dialog-content>
  <p>{{message}}</p>
</div>

<div mat-dialog-actions class="w-100 d-flex align-items-center justify-content-end">
  <button mat-button (click)="onDismiss()">{{'actions.cancel' | translate}}</button>
  <button mat-raised-button color="primary" (click)="onConfirm()">{{'actions.ok' | translate}}</button>
</div>