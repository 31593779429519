import {
  Component,
  OnInit,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import {
  ActivatedRoute,
  Router,
} from '@angular/router';

import {
  AppService,
  HelperPrepareParamsService,
  ProjectsService,
  TicketsService,
} from '../../../../core';

@Component({
  selector: 'app-ticket-create',
  templateUrl: './create.component.html',
  styleUrl: './create.component.scss',
})
export class TicketCreateComponent implements OnInit {
  isLoading = false;
  createTicket: FormGroup;
  // user: User;
  ticketId: string;
  projectId: string;
  typeOptions = [];
  levelOptions = [];
  priorityOptions = [];
  statusOptions = [];
  ticketConfigs: any;
  selectedTeamId: number;

  showLevelField = false;

  constructor(
    private route: ActivatedRoute,
    private appService: AppService,
    private fb: FormBuilder,
    private router: Router,
    private ticketsService: TicketsService,
    private projectsService: ProjectsService
  ) {

  }

  ngOnInit() {
    this.appService.title = 'tickets.create.title';
    this.createTicket = this.fb.group({
      title: [
        '',
        [
          Validators.required,
          Validators.minLength(5),
          Validators.maxLength(255),
        ],
      ],
      content: [
        '',
        [
          Validators.required,
          Validators.minLength(20),
        ],
      ],
      source: ['', [Validators.required]],
      custom_fields: this.fb.group({
        type: ['', [Validators.required]],
        level: [1],
        priority: ['', [Validators.required]],
        status: ['', [Validators.required]],
      }),
      page_url: [
        '',
        [
          Validators.required,
          Validators.pattern(/https:\/\/[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_.~#?&=]*)/),
        ],
      ],
    });

    this.route.paramMap.subscribe(params => {
      this.ticketId = params.get('id') || '';
      if (this.ticketId) {
        this.loadExistingTicket();
      } else {
        this.projectId = params.get('projectId') || '';
        this.setupNewTicket();
      }
    });

    this.createTicket.get('custom_fields.type').valueChanges.subscribe(type => {
      this.showLevelField = (type === 'Bug');
    });
  }

  setupNewTicket() {
    if (this.createTicket) {
      this.createTicket.get('source').setValue(this.projectId);
    }
    this.loadProjectAndConfigs();
  }

  loadExistingTicket() {
    this.ticketsService.getTicket(this.ticketId)
      .subscribe((ticket) => {
        this.projectId = ticket.source;
        this.loadProjectAndConfigs();
        this.createTicket.setValue({
          'title': ticket.title,
          'content': ticket.content,
          'source': ticket.source,
          'custom_fields': { ...ticket.custom_fields },
          'page_url': ticket.page_url,
        });
      });
  }

  loadProjectAndConfigs(): void {
    if (this.projectId) {
      this.projectsService.getProject(this.projectId).subscribe({
        next: (project) => {
          this.selectedTeamId = project.team_id;
          this.loadTicketConfigs();
        },
      });
    }
  }

  loadTicketConfigs(): void {
    this.ticketsService.getTicketConfigs().subscribe({
      next: (configs) => {
        this.ticketConfigs = configs.list;
        const projectConfig = this.ticketConfigs.find((config: {
          team_id: number
        }) => config.team_id === this.selectedTeamId);
        if (projectConfig) {
          this.typeOptions = projectConfig.custom_fields.type;
          this.levelOptions = projectConfig.custom_fields.level;
          this.priorityOptions = projectConfig.custom_fields.priority;
          this.statusOptions = projectConfig.custom_fields.status;
        }
      },
    });
  }

  onSubmit() {
    if (!this.createTicket.valid) {
      return;
    }

    this.isLoading = true;
    const ticketCreateParams = HelperPrepareParamsService.prepareParams(this.createTicket.value);
    const ticketObservable = this.ticketId
      ? this.ticketsService.updateTicket(parseInt(this.ticketId), ticketCreateParams)
      : this.ticketsService.createTicket(ticketCreateParams);

    ticketObservable.subscribe({
      next: ticket => {
        this.router.navigateByUrl('/tickets/' + ticket.id);
      },
      error: () => {
        this.isLoading = false;
      },
      complete: () => {
        this.isLoading = false;
      },
    });
  }
}
