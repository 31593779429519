import {
  Component,
  Input,
} from '@angular/core';

import {
  User,
  UserAvatarSize,
} from '../../../core';

@Component({
  selector: 'app-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss'],
})
export class AvatarComponent {
  _user: User = null;
  avatarUrl = null;

  @Input()
  set user(val: User) {
    this._user = val;
    if (this._user && this._user.avatar) {
      this.avatarUrl = this._user.avatar.presets[this.preset];
    } else {
      this.avatarUrl = null;
    }
  }
  @Input() preset = UserAvatarSize.MEDIUM;
  @Input() width = 100;
  @Input() profile = false;
  @Input() color = 'white';
  @Input() preview: string;
}
