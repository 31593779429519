<button mat-icon-button [matMenuTriggerFor]="menu" class="p-0">
  @for (locale of locales; track locale.code) {
    <span>
      @if (locale.code === currentLocale) {
        <span class="fi" [class]="locale.flag"></span>
      }
    </span>
  }
</button>
<mat-menu #menu="matMenu">
  @for (locale of locales; track locale.code) {
    <button mat-menu-item
            [value]="locale.code"
            (click)="useLanguage(locale)">
      @if (locale.flag) {
        <span class="me-1 fi" [class]="locale.flag"></span>
      }
      {{ locale.label | translate }}
    </button>
  }
</mat-menu>

