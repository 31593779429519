import { formatDate } from '@angular/common';

export class BaseService {
  protected _filterStorage = '_baseStorage';
  protected _parseDates = false;
  private _filter: any;

  get filter() {
    const storedFilter = JSON.parse(window.localStorage.getItem(this._filterStorage));
    if (storedFilter && typeof storedFilter === 'object') {
      this._filter = { ...this._filter, ...storedFilter };
    } else {
      this._filter = {};
    }
    if (this._parseDates) {
      for (const key in this._filter) {
        if (this._filter.hasOwnProperty(key) && !isNaN(Date.parse(this._filter[key])) && /^\d\d\/\d\d\/\d{4}$/.test(this._filter[key])) {
          this._filter[key] = new Date(Date.parse(this._filter[key]));
        }
      }
    }
    return { ...this._filter };
  }

  set filter(value) {
    this._filter = { ...value };
    for (const key in this._filter) {
      if (this._filter.hasOwnProperty(key) && this._filter[key] instanceof Date) {
        this._filter[key] = formatDate(this._filter[key], 'MM/dd/yyyy', 'en-US');
      }
    }
    window.localStorage.setItem(this._filterStorage, JSON.stringify(this._filter));
  }

  getFilter(storage) {
    const backupStorage = this._filterStorage;
    this._filterStorage = storage;
    const filter = this.filter;
    this._filterStorage = backupStorage;
    return filter;
  }

  setFilter(filter, storage) {
    const backupStorage = this._filterStorage;
    this._filterStorage = storage;
    this.filter = filter;
    this._filterStorage = backupStorage;
  }
}
