import { Component } from '@angular/core';

import { AppService } from '../../../core';

@Component({
  selector: '<app-page-header>',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.scss'],
})
export class PageHeaderComponent {
  title = '';
  isMobile: boolean;
  constructor(
    private appService: AppService
  ) {
    this.title = appService.title;
    this.appService.titleChange.subscribe({
      next: value => this.title = value,
    });
    this.isMobile = this.appService.isMobile;
  }
}
