import {
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';

import { Subscription } from 'rxjs';

import { AppService } from '../../../core';

export interface MenuRow {
  id: string,
  title: string,
  link: string,
  icon: string,
  children?: MenuRow[],
  expanded?: boolean;
  isHidden?: boolean;
}

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit, OnDestroy {
  @Input() menu: MenuRow[];
  active: string;
  expanded: boolean;

  activePageSubscription: Subscription;
  sideBarStateSubscription: Subscription;

  constructor(private appService: AppService) {
    this.activePageSubscription = this.appService.activePageChange.subscribe({
      next: value => this.active = value,
    });
    this.sideBarStateSubscription = this.appService.sideBarExpandedChange.subscribe(expanded => {
      this.expanded = expanded;
    });
  }

  ngOnInit(): void {
    this.expanded = this.appService.expanded;
    this.prepareMenu();
  }

  ngOnDestroy(): void {
    this.activePageSubscription.unsubscribe();
    this.sideBarStateSubscription.unsubscribe();
  }

  toggle() {
    this.expanded = !this.expanded;
    this.appService.expanded = this.expanded;
  }

  prepareMenu(): void {
    let i;
    let j;
    let url;
    for (i = 0; i < this.menu.length; i++) {
      if (this.menu[i].children) {
        const hidden = true;
        for (j = 0; j < this.menu[i].children.length; j++) {
          url = this.menu[i].children[j].link;
          if (this.active === this.menu[i].children[j].id){
            this.menu[i].expanded = true;
          }
        }
        this.menu[i].isHidden = hidden;
      } else {
        url = this.menu[i].link;
      }
    }
  }

  beforeSwitch(item: MenuRow): void {
    if (this.appService.isMobile) {
      this.toggle();
    }
  }
}
