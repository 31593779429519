export class SetErrors {
  static readonly type = '[Errors] Set Errors';
  constructor(public requestId: string, public errors: Record<string, any>) {}
}

export class ClearErrors {
  static readonly type = '[Errors] Clear Errors';
  constructor(public requestId: string | string[]) {}
}

export class ClearAllErrors {
  static readonly type = '[Errors] Clear All Errors';
}
