import { formatDate } from '@angular/common';
import {
  HttpClient,
  HttpContext,
  HttpEvent,
  HttpEventType,
  HttpHeaders,
  HttpParams,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import {
  Observable,
  throwError,
} from 'rxjs';
import {
  catchError,
  last,
  map,
} from 'rxjs/operators';

import { environment } from '../../../environment';
import {
  BaseResponse,
  PaginationRequest,
  Upload,
  upload,
} from '../models';

@Injectable()
export class ApiService {
  readonly ALLOWED_IMAGE_TYPES = ['image/png', 'image/jpeg', 'image/bmp'];
  readonly ALLOWED_IMAGE_SIZE = 1024 * 1024;
  constructor(private http: HttpClient, private translateService: TranslateService) {
  }

  public createFormData(object: object, form?: FormData, namespace?: string): FormData {
    const formData = form || new FormData();
    Object.keys(object).forEach(property => {
      const formKey = namespace ? `${namespace}[${property}]` : property;
      if (object[property as keyof object] !== this.translateService.instant('validations.date.invalid') && object[property as keyof object] as any instanceof Date) {
        formData.append(formKey, formatDate(object[property as keyof object], 'yyyy-MM-dd', 'en-US'));
      } else if (
        typeof object[property as keyof object] === 'object'
        && !(object[property as keyof object] as any instanceof File)
        && object[property as keyof object] !== null
      ) {
        this.createFormData(object[property as keyof object], formData, formKey);
      } else if (object[property as keyof object] === null) {
        formData.append(formKey, '');
      } else {
        formData.append(formKey, object[property as keyof object]);
      }
    });

    return formData;
  }

  private formatErrors(error: any) {
    return throwError(() => {
      const e: any = new Error(error.error.error || undefined);
      if (e !== undefined) {
        if (error.error.data) {
          e.data = error.error.data;
        }
        e.status = error.error.status;
        e.title = error.error.title;
      }
      return e;
    });
  }

  parsePaginationRequest(params: PaginationRequest): HttpParams {
    const paginationRequest = params;
    const pagination = new HttpParams()
      .set('per_page', String(paginationRequest.per_page))
      .set('page', String(paginationRequest.page));
    return this.parseFilters(paginationRequest.filter, pagination);
  }

  parseFilters(filter: Record<string, any> | undefined, params: HttpParams | null = null): HttpParams {
    if (!params) {
      params = new HttpParams();
    }
    if (filter) {
      for (const key of Object.keys(filter)) {
        if (Array.isArray(filter[key])) {
          for (const v of filter[key]) {
            params = params.append(key.concat('[]'), v);
          }
        } else {
          params = params.set(key, filter[key]);
        }
      }
    }
    return params;
  }

  get<T>(path: string, params: PaginationRequest | HttpParams = new HttpParams(), headers: HttpHeaders | null = null): Observable<BaseResponse<T>> {
    if (!(params instanceof HttpParams)) {
      params = this.parsePaginationRequest(params);
    }
    const options: Record<string, any> = { params };
    if (headers) {
      options['headers'] = headers;
      if (headers.has('Accept') && headers.get('Accept')?.includes('html')) {
        options['responseType'] = 'text';
      }
    }
    return this.http.get(this.getApiUrl(path), options).pipe(catchError(this.formatErrors));
  }

  put<T>(path: string, body: object | FormData = {}, isUpload = false, context?: HttpContext): Observable<BaseResponse<T>> {
    if (isUpload && body instanceof FormData) {
      return this.request('PUT', path, body, { reportProgress: true });
    }
    return this.http.put(this.getApiUrl(path), JSON.stringify(body), { context }).pipe(catchError(this.formatErrors));
  }

  patch<T>(path: string, body: object | FormData = {}, isUpload = false, context?: HttpContext): Observable<BaseResponse<T>> {
    if (isUpload && body instanceof FormData) {
      return this.request('PATCH', path, body, { reportProgress: true });
    }
    return this.http.patch(this.getApiUrl(path), JSON.stringify(body), { context }).pipe(catchError(this.formatErrors));
  }

  post<T>(path: string, body: object | FormData = {}, isUpload = false, context?: HttpContext): Observable<BaseResponse<T>> {
    if (isUpload && body instanceof FormData) {
      return this.request('POST', path, body, { reportProgress: true });
    }
    return this.http.post(this.getApiUrl(path), JSON.stringify(body), { context }).pipe(catchError(this.formatErrors));
  }

  delete<T>(path: string): Observable<BaseResponse<T>> {
    return this.http.delete(this.getApiUrl(path)).pipe(catchError(this.formatErrors));
  }

  request(method: string, path: string, data: FormData, options: object = {}): Observable<any> {
    const req = new HttpRequest(method, this.getApiUrl(path), data, options);
    return this.http.request(req).pipe(
      map(event => this.getEventMessage(event)),
      last(), // return last (completed) message to caller
      catchError(this.formatErrors)
    );
  }

  private getEventMessage(event: HttpEvent<any>) {
    if (event.type === HttpEventType.Response) {
      return event.body;
    }
  }

  private getApiUrl(path: string): string {
    return environment.apiVersion
      ? `${environment.apiUrl}/${environment.apiVersion}${path}`
      : `${environment.apiUrl}${path}`;
  }

  upload(file: File): Observable<Upload> {
    const data = new FormData();
    data.append('file', file);
    return this.http
      .post(this.getApiUrl('/file'), data, {
        reportProgress: true,
        observe: 'events',
      })
      .pipe(upload());
  }
}
