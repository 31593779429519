import {
  CollectionViewer,
  DataSource,
} from '@angular/cdk/collections';

import {
  BehaviorSubject,
  Observable,
} from 'rxjs';

import { PaginationContent } from '../models';

export class AjaxDataSource<T> implements DataSource<T> {
  private dataSubject = new BehaviorSubject<T[]>([]);
  private loadingSubject = new BehaviorSubject<boolean>(true);
  public totalSubject = new BehaviorSubject<number>(0);

  public loading$ = this.loadingSubject.asObservable();
  public total$ = this.totalSubject.asObservable();

  constructor(data: T[] = []) {
    this.data = data;
  }

  connect(collectionViewer: CollectionViewer): Observable<T[]> {
    return this.dataSubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.dataSubject.complete();
    this.loadingSubject.complete();
    this.totalSubject.complete();
  }

  load(observable: Observable<PaginationContent<T>>) {
    this.loadingSubject.next(true);
    observable.subscribe({
      next: response => {
        this.data = response.list;
        this.total = response.total;
        this.loadingSubject.next(false);
      },
      error: () => {
        this.loadingSubject.next(false);
      },
    });
  }

  set loading(value: boolean) {
    this.loadingSubject.next(value);
  }

  set data(rows: T[]) {
    this.dataSubject.next(rows);
  }

  set total(value: number) {
    this.totalSubject.next(value);
  }
}
