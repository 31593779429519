<mat-form-field [appearance]="config.appearance ?? 'fill'" [class]="config.class">
  @if (config.label) {
    <mat-label>{{ 'form.' + config.label + '.label' | translate }}</mat-label>
  }
  <input matInput
         autocomplete="new-password"
         [formControl]="inputField"
         [required]="required && !isDisabled"
         [type]="config.type ?? 'text'"
         (blur)="handleOnBlur()">
  @if (config.hint) {
    <mat-hint>{{ 'form.' + config.label + '.hint' | translate }}</mat-hint>
  }
  @if (buttonConfig) {
    <mat-icon
      class="suffix"
      matSuffix
      [style.color]="buttonConfig.color"
      (click)="clickButton.emit()">
      {{ buttonConfig.icon }}
    </mat-icon>
  }
  <div class="input-button">
    <ng-content></ng-content>
  </div>
  <mat-error>
    <app-validation-error-render [errors]="errors" [label]="config.label"></app-validation-error-render>
  </mat-error>
</mat-form-field>
