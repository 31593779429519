import { Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';

import { TranslateService } from '@ngx-translate/core';

import { ToastsService } from './toasts.service';

@Injectable()
export class CheckVersionService {
  constructor(
    private toastsService: ToastsService,
    private swUpdate: SwUpdate,
    private translateService: TranslateService,
  ) {
  }

  checkVersion(): void {
    if (this.swUpdate.isEnabled) {
      this.swUpdate.versionUpdates.subscribe((event) => {
        if (event.type === 'VERSION_DETECTED') {
          this.toastsService.add(
            this.translateService.instant('messages.oldSiteVersion'),
            this.translateService.instant('messages.reloadPage'),
            0
          ).afterDismissed().subscribe(() => window.location.reload());
        }
      });
    }
  }
}
