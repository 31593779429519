export interface AdminSetting {
  name: string;
  type: AdminSettingsType;
  value: AdminSettingValue;
}

export interface AdminArraySetting {
  name: string;
  type: AdminSettingsType;
  value: Array<any>;
}

export interface AdminSettingValue {
  data: string | number | boolean;
}

export enum AdminSettingsType {
  emails = 'array_of_emails',
  integer = 'integer',
  string = 'string',
  boolean = 'boolean'
}

export function getFieldType(settingType: string) {
  switch (settingType){
    case AdminSettingsType.emails:
      return 'email';
    case AdminSettingsType.string:
      return 'text';
    case AdminSettingsType.integer:
      return 'number';
    case AdminSettingsType.boolean:
      return 'boolean';
  }
}

