import { Component } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';

import {
  Locale,
  LocaleService,
} from '../../../core';

interface Language {
  code: Locale;
  label: string;
  flag?: string;
}

@Component({
  selector: 'app-locale-switcher',
  templateUrl: './locale-switcher.component.html',
})
export class LocaleSwitcherComponent {
  currentLocale: Locale;

  constructor(
    private translateService: TranslateService,
    private localeService: LocaleService,
  ) {
    this.currentLocale = localeService.getCurrentLocale();
  }

  locales: Array<Language> = [
    {
      code: Locale.en,
      label: `locale.${Locale.en}`,
      flag: 'fi-us',
    },
    {
      code: Locale.uk,
      label: `locale.${Locale.uk}`,
      flag: 'fi-ua',
    },
  ];

  useLanguage(language: Language): void {
    this.localeService.changeLocale(language.code);
    this.currentLocale = this.localeService.getCurrentLocale();
  }
}
