<H4>{{'projects.show.addMember' | translate}}</H4>
<div class="assign-member">
  <mat-form-field appearance="fill" class="w-60">
    <mat-label>{{'projects.show.member' | translate}}</mat-label>
    <mat-select #addUserSelect>
      <mat-option *ngFor="let member of teamMembers" [value]="member.id">
        {{ member.member.first_name + ' ' + member.member.last_name}}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <button class="action-button"
          mat-flat-button
          color="primary"
          type="button"
          (click)="onAddUser()"
          [disabled]="!teamMembers.length"
  >
    {{'projects.edit.assignMember' | translate}}
    <mat-icon>personAdd</mat-icon>
  </button>
</div>
<div>
  Project Members
</div>
<table mat-table [dataSource]="datasource" aria-label="members" matSort>
  <ng-container matColumnDef="id">
    <th class="w-25" scope="row" mat-header-cell *matHeaderCellDef>
      {{ 'pages.projects.id' | translate }}
    </th>
    <td mat-cell *matCellDef="let member"> {{ member.id }}</td>
  </ng-container>
  <ng-container matColumnDef="user_id">
    <th class="w-25" scope="row" mat-header-cell *matHeaderCellDef>
      {{ 'pages.projects.userId' | translate }}
    </th>
    <td mat-cell *matCellDef="let member"> {{ member.user_id }}</td>
  </ng-container>
  <ng-container matColumnDef="role">
    <th class="w-25" scope="row" mat-header-cell *matHeaderCellDef>
      {{ 'pages.projects.role' | translate }}
    </th>
    <td mat-cell *matCellDef="let member"> {{ member.role }}</td>
  </ng-container>
  <ng-container matColumnDef="name">
    <th class="w-25" scope="row" mat-header-cell *matHeaderCellDef>
      {{ 'pages.projects.name' | translate }}
    </th>
    <td mat-cell *matCellDef="let member"> {{ member.first_name + ' ' + member.last_name }}</td>
  </ng-container>
  <ng-container matColumnDef="actions">
    <th class="w-25" scope="row" mat-header-cell *matHeaderCellDef>
      {{'actions.action' | translate}}
    </th>
    <td mat-cell *matCellDef="let member">
      <button mat-icon-button type="button" class="ms-2" (click)="unassignTeamMember(member.team_member_id)">
        <mat-icon class="delete-icon">delete</mat-icon>
      </button>
    </td>
  </ng-container>
  <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>

<mat-paginator #pagination
               [pageSizeOptions]="pageSizeOptions"
               [length]="datasource.total$ | async">
</mat-paginator>
