import { formatDate } from '@angular/common';
import { Injectable } from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms';

import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

import { ToastsService } from './toasts.service';

import {
  FormValidator,
  ValidationCallback,
} from '../models';

@Injectable()
export class ValidationService {

  form: FormGroup = null;
  fields: FormValidator = {};
  ready = false;

  showErrors = true;
  listeners: Record<string, {element: Element; listener: EventListener}> = {};
  subscriptions: Record<string, Subscription> = {};

  private loopChecker: Record<string, number> = {};

  constructor(
    private toastsService: ToastsService,
    private translateService: TranslateService,
  ) {
  }
  isValidDate = d => d && d instanceof Date && !isNaN(d.getTime());
  isEmptyValue = v => !v || (Array.isArray(v) && v.filter(i => i).length === 0) || (typeof v === 'string' && !v.trim().length);

  getFirstDayNextMonth() {
    const date = new Date();
    return new Date(date.getFullYear(), date.getMonth() + 1, 1);
  }

  getYesterday() {
    const date = new Date();
    date.setDate(date.getDate() - 1);
    return date;
  }

  clear() {
    this.form.updateValueAndValidity();
  }

  init(form: FormGroup, fields: FormValidator) {
    this.form = form;
    this.fields = {};
    this.subscriptions = {};
    const fieldNames = Object.keys(fields);
    for (const name of fieldNames) {
      this.fields[name] = fields[name];
    }
    this.ready = true;
  }

  add(field: string, validators: ValidationCallback[], mode: 'blur'|'change' = 'blur') {
    if (!this.fields.hasOwnProperty(field)) {
      this.fields[field] = { mode, validators };
    } else {
      this.fields[field].validators = this.fields[field].validators.concat(validators);
    }
  }

  addListeners(fields: string|string[] = null) {
    fields = this.normalizeFields(fields);
    this.removeListeners(fields);
    fields.forEach(f => {
      const elements = document.querySelectorAll(`[inputerror="${f}"]`);
      const element = elements.length ? elements[0] : null;
      if (this.fields[f].mode === 'blur') {
        if (element) {
          this.listeners[f] = {
            element,
            listener: (e) => { this.validate(f) },
          };
          this.listeners[f].element.addEventListener('blur', this.listeners[f].listener, true);
        }
      } else if (this.fields[f].mode === 'change') {
        if (element) {
          this.listeners[f] = {
            element,
            listener: (e) => { setTimeout(() => this.validate(f), 500) },
          };
          this.listeners[f].element.addEventListener('blur', this.listeners[f].listener, true);
        }
        this.subscriptions[f] = this.form.get(f).valueChanges.subscribe(() => {
          this.validate(f);
        });
      }
    });
  }

  removeListeners(fields: string|string[] = null) {
    this.normalizeFields(fields).forEach(f => {
      if (this.listeners.hasOwnProperty(f)) {
        this.listeners[f].element.removeEventListener('blur', this.listeners[f].listener, true);
        delete this.listeners[f];
      }
      if (this.subscriptions.hasOwnProperty(f)) {
        this.subscriptions[f].unsubscribe();
        delete this.subscriptions[f];
      }
    });
  }

  reset(field: string = null) {
    this.removeListeners(field);
    if (field === null) {
      this.ready = false;
      this.form = null;
      this.fields = {};
      this.subscriptions = {};
      this.listeners = {};
    } else if (this.fields.hasOwnProperty(field)) {
      this.fields[field].validators = [];
    }
  }

  validate(fields: string|string[] = null, showErrors: boolean = null) {
    this.loopChecker = {};
    return this.check(fields, showErrors);
  }

  required(message = this.translateService.instant('validations.required')) {
    return (form, field) => {
      if (this.isEmptyValue(form.get(field).value)) {
        return message;
      }
      return null;
    };
  }

  number(message = 'This field must have a numeric value'){
    return (form, field) => {
      const value = form.get(field).value;
      if (value && (isNaN(parseFloat(value)) || !isFinite(value))) {
        return message;
      }
      return null;
    };
  }

  requiredIf(condition: (form: FormGroup) => boolean, message = this.translateService.instant('validations.required')) {
    return (form, field) => {
      if (condition(form) && this.isEmptyValue(form.get(field).value)) {
        return message;
      }
      return null;
    };
  }

  date(message = this.translateService.instant('validations.date.invalid')) {
    return (form, field) => {
      if (!this.isEmptyValue(form.get(field).value) && !this.isValidDate(form.get(field).value)) {
        return message;
      }
      return null;
    };
  }

  minLength(length, message = this.translateService.instant('validations.length.min')) {
    return this.checkLength(length, message, (a, b) => a < b, 'minLength');
  }

  maxLength(length, message = this.translateService.instant('validations.length.max')) {
    return this.checkLength(length, message, (a, b) => a > b, 'maxLength');
  }

  exactLength(length, message = this.translateService.instant('validations.length.eq')) {
    return this.checkLength(length, message, (a, b) => a !== b, 'exactLength');
  }

  onlyDateDay(dateDay, message = this.translateService.instant('validations.date.min')) {
    return (form, field) => {
      let value = form.get(field).value;
      if (this.isValidDate(value)) {
        value = value.getDate();
        if (value !== dateDay) {
          return message;
        }
      }
      return null;
    };
  }

  availableDays(availableDays, message = '$field should be $dates') {
    const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    message = message.replace('$dates', availableDays.map((day) => days[day]).join(', '));
    return (form, field) => {
      message = message.replace('$field', field);
      const value = form.get(field).value;
      if (this.isValidDate(value)) {
        if (!availableDays.includes(value.getDay())) {
          return message;
        }
      }
      return null;
    };
  }

  minDate(date, message = this.translateService.instant('validations.date.min')) {
    return this.checkDate(date, message, (a, b) => a < b);
  }

  maxDate(date, message = this.translateService.instant('validations.date.max')) {
    return this.checkDate(date, message, (a, b) => a > b);
  }

  minIntegerValue(value, message = this.translateService.instant('validations.integer.min')) {
    return this.checkIntegerValue(parseInt(value, 10), message, (a, b) => a < b);
  }

  maxIntegerValue(value, message = this.translateService.instant('validations.integer.max')) {
    return this.checkIntegerValue(parseInt(value, 10), message, (a, b) => a > b);
  }

  phone(message = this.translateService.instant('validations.phone.incorrectFormat')) {
    return (form, field) => {
      const value = form.get(field).value;
      if (value && !(/^\(\d{3}\) \d{3}-\d{4}$/.test(value))) {
        return message;
      }
      return null;
    };
  }

  email(message = this.translateService.instant('validations.email.incorrectFormat')) {
    return (form, field) => {
      const value = form.get(field).value;
      if (value && !(/^((?:[\p{L}0-9.!#$%&'*+\/=?^_`{|}~-]+)*@[\p{L}0-9-._]+)$/ui.test(value))) {
        return message;
      }
      return null;
    };
  }

  zip(message = this.translateService.instant('validations.zip.incorrectFormat')) {
    return (form, field) => {
      const value = form.get(field).value;
      if (value && !(/^\d{5}(-\d{4})?$/.test(value))) {
        return message;
      }
      return null;
    };
  }

  password(minLen: number, lowercase = false, uppercase = false, numbers = false,
    specials = false, message = this.translateService.instant('validations.password.invalid')) {
    return (form, field) => {
      const value = form.get(field).value;
      const specialCharsPattern = specials ? '(?=.*[^A-Za-z0-9])' : '';
      const numbersPattern = numbers ? '(?=.*[0-9])' : '';
      const lowerCasePattern = lowercase ? '(?=.*[a-z])' : '';
      const upperCasePattern = uppercase ? '(?=.*[A-Z])' : '';
      const pattern = lowerCasePattern + upperCasePattern + numbersPattern + specialCharsPattern + '.{' + minLen + ',}';
      if (value && !(new RegExp(pattern).test(value))) {
        return message;
      }
      return null;
    };
  }

  url(message = this.translateService.instant('validations.uri.invalid')) {
    return (form, field) => {
      const value = form.get(field).value;
      const regex = /^(?:http(s)?:\/\/)[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:\/?#[\]@!\$&'\(\)\*\+,;=.]+$/;
      if (value && !regex.test(value)) {
        return message;
      }
      return null;
    };
  }

  equals(otherValueName: string, message: string) {
    return (form, field) => {
      const value = form.get(field).value;
      const otherValue = form.get(otherValueName).value;
      if (value && value !== otherValue) {
        return message;
      }
      return null;
    };
  }

  refresh(fields: string|string[]) {
    return (form, field) => {
      this.check(fields);
      return null;
    };
  }

  private checkLength(length, message, operator, validationType : string) {
    message = message.replace('$length', length);
    return (form, field) => {
      if (!form.get(field).touched) {
        return null;
      }
      const value = form.get(field).value;
      if (!this.isEmptyValue(value) && typeof value === 'string' && operator(value.length, length)) {
        return message;
      }
      return null;
    };
  }

  private checkDate(date, message, operator) {
    message = message.replace('$date', formatDate(date, 'M/dd/yyyy', 'en-US'));
    if (date instanceof Date) {
      date = formatDate(date, 'yyyy-MM-dd', 'en-US');
    }
    return (form, field) => {
      let value = form.get(field).value;
      if (this.isValidDate(value)) {
        value = formatDate(value, 'yyyy-MM-dd', 'en-US');
        if (operator(value, date)) {
          return message;
        }
      }
      return null;
    };
  }

  private checkIntegerValue(intValue, message, operator) {
    message = message.replace('$value', intValue);
    return (form, field) => {
      const formValue = form.get(field).value;
      if (formValue && /(?=.*\d)/.test(formValue)) {
        if (operator(parseInt(formValue, 10), intValue)) {
          return message;
        }
      }
      return null;
    };
  }

  private normalizeFields(fields: string|string[]): string[] {
    if (fields === null) {
      fields = Object.keys(this.fields);
    } else if (!Array.isArray(fields)) {
      fields = [fields];
    }
    return fields;
  }

  private check(fields: string|string[] = null, showErrors: boolean = null) {
    if (!this.ready) { return true }
    const errors = {};
    if (showErrors === null) {
      showErrors = this.showErrors;
    }

    this.normalizeFields(fields).forEach(f => {
      if (!this.fields.hasOwnProperty(f)) {
        return true;
      }
      if (!this.loopChecker.hasOwnProperty(f)) {
        this.loopChecker[f] = 0;
      } else {
        return;
      }
      this.form.get(f).updateValueAndValidity({ emitEvent: false });
      for (const validator of this.fields[f].validators) {
        const fieldResult = validator(this.form, f);
        if (fieldResult) {
          errors[f] = fieldResult;
          break;
        }
      }
    });

    if (showErrors) {
      const keys = Object.keys(errors);
      for (const field of keys) {
        this.form?.get(field)?.setErrors(errors[field]);
      }
      return keys.length === 0;
    } else {
      return errors;
    }
  }

  matchValidator(
    matchTo: string,
    message?: string,
    reverse?: boolean
  ): ValidatorFn {
    if (!message){
      message = 'Fields doesn\'t match';
    }
    return (control: AbstractControl):
      ValidationErrors | null => {
      if (control.parent && reverse) {
        const c = (control.parent?.controls as any)[matchTo] as AbstractControl;
        if (c) {
          c.updateValueAndValidity();
        }
        return null;
      }
      return !!control.parent &&
      !!control.parent.value &&
      control.value ===
      (control.parent?.controls as any)[matchTo].value
        ? null
        : { matching: { message } };
    };
  }

  differValidator(
    matchTo: string,
  ): ValidatorFn {
    return (control: AbstractControl):
      ValidationErrors | null => {
      return !!control.parent &&
      !!control.parent.value &&
      control.value !==
      (control.parent?.controls as any)[matchTo].value
        ? null
        : { differ: {} };
    };
  }

  getErrorMessage(form: FormGroup | FormArray, formControlName: string) {
    const errorType = Object.keys(form.get(formControlName)?.errors)[0];
    if (form.get(formControlName)?.errors[errorType]?.message){
      return form.get(formControlName)?.errors[errorType].message;
    }
    const err = form.get(formControlName)?.errors[errorType];
    switch (errorType) {
      case 'email':
        return 'Invalid email format';
      case 'required':
        return 'This field cannot be left empty';
      case 'minlength':
        return 'This field must have a minimum length of ' + err.requiredLength + ' characters';
      case 'maxlength':
        return 'This field must have a maximum length of ' + err.requiredLength + ' characters';
    }
  }

  setError(form: FormGroup, field: string, errorName: string, message: string) {
    form.get(field).setErrors({ [errorName]: { message: message } });
  }

  setServerErrors(error: any, form: FormGroup) {
    if (!error?.data){
      return;
    }
    for (const key of Object.keys(error.data)){
      const control = form.get(key);
      control.setErrors({ serverError: { message: error.data[key][0] } });
    }
  }
}
