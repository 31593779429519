import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { map } from 'rxjs/operators';

import { ApiService } from './api.service';
import { AppService } from './app.service';

import {
  Social,
  SocialProvider,
  Token,
} from '../models';

@Injectable()
export class SocialService {

  constructor(
    private apiService: ApiService,
    private router: Router,
  ) {
  }

  getSocialLink(provider: SocialProvider) {
    const appType = AppService.appType;
    this.setReturnUrl();
    return this.apiService.get<Social>(`/auth-socialite/${provider}/${appType}/login`)
      .pipe(map(response => {
        return response.data;
      }));
  }

  handler(provider: SocialProvider, params: HttpParams) {
    const appType = AppService.appType;
    return this.apiService.get<Token>(`/auth-socialite/${provider}/${appType}/handler`, params)
      .pipe(map(response => {
        return response.data;
      }));
  }

  deleteSocial(provider: SocialProvider) {
    return this.apiService.delete<null>(`/auth-socialite/${provider}`);
  }

  setReturnUrl(): void {
    localStorage.setItem('returnUrl', this.router.url);
  }

  getReturnUrl(): string {
    return localStorage.getItem('returnUrl');
  }
}
