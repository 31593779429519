import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApiService } from './api.service';

@Injectable()
export class AdminSettingsService {
  constructor(
    private apiService: ApiService,
  ) {
  }

  fetchSettings(): Observable<Array<any>> {
    return this.apiService.get('/admin/app_settings').pipe(
      map(response => Object.entries(response.data).map(([key, value]) => (value)))

    ) as Observable<any>;
  }

  updateSetting(params: object): Observable<any> {
    return this.apiService.post('/admin/app_settings', params)
      .pipe(map(
        response => {
          if (response.data) {
            return response.data;
          }
        }
      ));
  }
}
