<mat-toolbar color="primary">
  <mat-toolbar-row>
    <span class="header-logo" [ngClass]="isMobile ? 'mobile': ''" (click)="clickLogo()">{{ appName }}</span>
    <span class="header-spacer"></span>
    <app-theme-toggle></app-theme-toggle>
    <app-locale-switcher></app-locale-switcher>
    @if (isUserAuth) {
      <app-notification></app-notification>
    }
    @if (showIcons) {
      @if (user) {
        <div>
          @if (!isMobile) {
            <a class="menu-button" [ngClass]="isMobile ? 'mobile': ''" mat-stroked-button [matMenuTriggerFor]="menu">
              <mat-icon>expand_more</mat-icon>
              {{ user.first_name + ' ' + user.last_name }}
            </a>
          }
          @if (isMobile) {
            <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
              <mat-icon>more_vert</mat-icon>
            </button>
          }
        </div>
      }
    }
  </mat-toolbar-row>
</mat-toolbar>
<mat-menu #menu="matMenu" xPosition="before">
  <button mat-menu-item routerLink="/profile">
    <mat-icon>person</mat-icon>
    <span>{{ 'header.actions.profile' | translate }}</span>
  </button>
  <button mat-menu-item (click)="logout()">
    <mat-icon>logout</mat-icon>
    <span>{{ 'header.actions.logout' | translate }}</span>
  </button>
</mat-menu>
