import {
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import {
  Subject,
  takeUntil,
} from 'rxjs';

import { environment } from '../../../../environment';
import {
  AppService,
  User,
  UserService,
} from '../../../core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  @Input() showIcons = false;

  private destroy$: Subject<void> = new Subject();

  isMobile: boolean;
  isUserAuth: boolean = false;
  isAdmin: boolean;
  appName: string = environment.project;
  user: User;

  constructor(private appService: AppService, private route: ActivatedRoute, private userService: UserService ) {
    this.isAdmin = this.route.snapshot.data['type'] !== 'user';
    this.isMobile = this.appService.isMobile;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngOnInit() {
    this.userService.isAuthenticatedSubject.pipe(takeUntil(this.destroy$)).subscribe(isUserAuth => {
      this.isUserAuth = isUserAuth;
    });
    this.userService.currentUser.pipe(takeUntil(this.destroy$)).subscribe({
      next: user => {
        this.user = user;
      },
    });
  }

  logout() {
    this.userService.logout();
  }

  clickLogo() {
    this.userService.openHomePage();
  }
}
