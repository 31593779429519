import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { map } from 'rxjs/operators';

import { ApiService } from './api.service';
import { BaseService } from './base.service';

import {
  PaginationContent,
  User,
} from '../models';

@Injectable()
export class ActionLogsService extends BaseService {
  constructor(private apiService: ApiService) {
    super();
  }

  fetchActionLogs(params?: HttpParams) {
    if (!params) {
      params = new HttpParams();
    }
    return this.apiService
      .get<PaginationContent<User>>('/admin/action_logs', params)
      .pipe(map(response => response.data));
  }

  options() {
    return this.apiService.get('/admin/action_logs/options').pipe(map(response => response.data));
  }
}
