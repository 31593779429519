import { formatDate } from '@angular/common';
import { HttpParams } from '@angular/common/http';

export class HelperPrepareParamsService {
  public static prepareParams (
    params: Record<string, any>,
    dateFields: Array<string> = [],
    onlyDate = false,
    dateFormat = 'yyyy-MM-dd',
  ): Record<string, any> {
    const result: Record<string, any> = {};
    for (const field of Object.keys(params)) {
      let returnValue = params[field];
      if (returnValue) {
        if (dateFields.includes(field)) {
          if (HelperPrepareParamsService.isValidDate(params[field])) {
            if (onlyDate) {
              returnValue = formatDate(params[field] as string | Date, dateFormat, 'en-US');
            } else {
              const endTime = field.includes('end') ? '23:59:59' : '00:00:00';
              const time = field.includes('start') ? '00:00:00' : endTime;
              returnValue = formatDate(params[field] as string | Date, `${dateFormat} ${time}`, 'en-US');
            }
          }
        }

        result[field] = returnValue;
      }
    }

    return result;
  }

  public static prepareHttpParams(
    params: Record<string, any>,
    fields: Array<string>,
    onlyDate = false,
    dateFormat = 'yyyy-MM-dd',
  ): HttpParams {
    const data = HelperPrepareParamsService.prepareParams(params, fields, onlyDate, dateFormat);
    return (new HttpParams()).appendAll(data);
  }

  private static isValidDate = d => !isNaN(Date.parse(d)) || (d instanceof Date && !isNaN(d.getTime()));
}
