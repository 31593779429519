import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApiService } from './api.service';

import {
  Notification,
  NotificationStatistic,
  PaginationContent,
} from '../models';

@Injectable()
export class NotificationsService {
  constructor(
    private apiService: ApiService,
  ) {
  }

  fetchNotifications(params?: HttpParams): Observable<PaginationContent<Notification>> {
    if (!params) {
      params = new HttpParams();
    }
    return this.apiService.get<PaginationContent<Notification>>('/notifications', params).pipe(map(response => response.data));
  }

  getStatistic(): Observable<NotificationStatistic> {
    return this.apiService.get<NotificationStatistic>('/notifications/statistic').pipe(map(response => response.data));
  }

  readNotification(id: number | string): Observable<Notification> {
    return this.apiService.post<Notification>(`/notifications/${id}/read`).pipe(map(response => response.data));
  }
}
