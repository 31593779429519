<mat-card appearance="outlined">
  <div class="card-header">
    <div class="avatar-wrapper">
      <img class="img-avatar" [src]="avatar" alt="avatar">
      <mat-card-title>{{ comment.creator_name }}</mat-card-title>
    </div>
    <div class="action-buttons">
      <button class="cancel-button" mat-stroked-button color="primary" type="button" (click)="onCancel()">
        {{ 'actions.cancel' | translate }}
        <mat-icon>cancel</mat-icon>
      </button>
      <button class="cancel-button"
              mat-flat-button
              color="primary"
              type="button"
              (click)="onSave()"
              [disabled]="!editCommentForm.dirty || !editCommentForm.valid || isLoading"
      >
        {{ 'actions.save' | translate }}
        <mat-icon>save</mat-icon>
      </button>
    </div>
  </div>
  <mat-card-content>
    <form [formGroup]="editCommentForm">
      <mat-form-field class="example-full-width">
        <mat-label>{{ comment.id ? 'Edit a comment' : 'Add a comment' }}</mat-label>
        <textarea class="content-textarea"
                  matInput [cdkTextareaAutosize]="true"
                  placeholder="Your comment..."
                  formControlName="content"
        >{{ comment.content }}
              </textarea>
        @if (editCommentForm.get('content')?.errors) {
          <mat-error>{{ parseError(editCommentForm, 'content') }}</mat-error>
        }
      </mat-form-field>
    </form>
  </mat-card-content>
  <mat-card-footer>
    <small>{{ comment.created_at | date:'short' }}</small>
  </mat-card-footer>
</mat-card>
