import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';

import { AppService } from '../../core';

@Injectable({
  providedIn: 'root',
})
@Injectable()
export class PortalGuard implements CanActivate {
  constructor(
    private router: Router
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const portal = route.data.app;

    if (AppService.appType === portal) {
      return true;
    }
    this.router.navigateByUrl('/');
    return false;
  }

}
