import { Injectable } from '@angular/core';

import { map } from 'rxjs/operators';

import { ApiService } from './api.service';

import { Dashboard } from '../models';

@Injectable()
export class DashboardService {
  constructor(private apiService: ApiService) {
  }

  getDashboardData() {
    return this.apiService.get<Dashboard>('/admin/reports')
      .pipe(map(
        response => {
          if (response.data) {
            return response.data;
          }
        }
      ));
  }
}
