import { Media } from './media.model';

export enum NotificationType {
  PRIMARY = 'primary',
  REMINDER = 'reminder',
}

export interface Notification {
  id: number;
  user_id: number;
  transport: string;
  type: string;
  target?: string;
  target_id?: string;
  title: string;
  text: string;
  seen_at?: Date | string;
  created_at?: Date | string;
  updated_at?: Date | string;

  image?: Media;
}

export interface NotificationStatistic {
  count: number;
}
