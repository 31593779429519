import { Injectable } from '@angular/core';

import {
  Action,
  createSelector,
  State,
  StateContext,
} from '@ngxs/store';

import {
  ClearAllErrors,
  ClearErrors,
  SetErrors,
} from './errors.actions';

type ErrorsStateModel = Record<string, Record<string, any>>

@State<ErrorsStateModel>({
  name: 'errors',
  defaults: {},
})
@Injectable()
export class ErrorsState {
  static errors(requestId: string) {
    return createSelector([this], (state: ErrorsStateModel) => {
      return state[requestId] || null;
    });
  }

  @Action(SetErrors)
  setErrors(ctx: StateContext<ErrorsStateModel>, action: SetErrors) {
    const state = ctx.getState();
    ctx.patchState({
      ...state,
      [action.requestId]: action.errors,
    });
  }

  @Action(ClearAllErrors)
  clearAllErrors(ctx: StateContext<ErrorsStateModel>) {
    ctx.setState({});
  }

  @Action(ClearErrors)
  clearErrors(ctx: StateContext<ErrorsStateModel>, action: ClearErrors) {
    let newState: ErrorsStateModel = {};
    if (typeof action.requestId === 'string') {
      newState[action.requestId] = null;
    } else {
      newState = action.requestId.reduce(
        (accumulator: ErrorsStateModel, value) => ((accumulator[value] = null), accumulator),
        {},
      );
    }
    ctx.patchState(newState);
  }
}
