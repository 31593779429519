import {
  Component,
  OnInit,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';

import { of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import {
  AppService,
  HelperPrepareParamsService,
  PaginationContent,
  ProjectsService,
  Team,
  TeamsService,
  User,
  UserService,
} from '../../../../core';

@Component({
  selector: 'app-admin-project-create',
  templateUrl: './create.component.html',
  styleUrl: './create.component.scss',
})
export class ProjectCreateComponent implements OnInit{
  createProject: FormGroup;
  user: User;
  teams: any[] = [];
  constructor(
    private appService: AppService,
    private fb: FormBuilder,
    private router: Router,
    private userService: UserService,
    private projectsService: ProjectsService,
    private teamsService: TeamsService
  ) {

  }

  ngOnInit() {
    this.appService.title = 'projects.create.title';
    this.createProject = this.fb.group({
      name: ['', [Validators.required, Validators.minLength(4), Validators.maxLength(255)]],
      key: ['', [Validators.required, Validators.minLength(4), Validators.maxLength(255)]],
      team_id: ['', [Validators.required, Validators.pattern('^[0-9]+$')]],
    });
    this.userService.currentUser.pipe(
      switchMap(user => {
        this.user = user;
        return user ? this.teamsService.fetchTeams() : of({ list: [] } as PaginationContent<Team>);
      })
    ).subscribe(result => {
      this.teams = result.list.map(team => ({ teamId: team.id, teamName: team.name }));
    });
  }

  onSubmit() {
    if (this.createProject.valid) {
      const projectCreateParams = HelperPrepareParamsService.prepareParams(this.createProject.value);
      const service = this.projectsService.createProject(projectCreateParams);
      service.subscribe({
        next: project => {
          this.router.navigateByUrl('/projects/' + project.id);
        },
      });
    }
  }
}
