import {
  Injectable,
  Injector,
} from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import {
  Observable,
  Subject,
  throwError,
} from 'rxjs';
import {
  catchError,
  map,
} from 'rxjs/operators';

import { ApiService } from './api.service';

import { Token } from '../models';

@Injectable()
export class JwtService {
  public accessTokenSubject: Subject<string> = new Subject<string>();

  private apiService: ApiService | undefined;

  constructor(private inj: Injector, private translateService: TranslateService) {
  }

  getAccessToken(): string {
    return window.localStorage['accessToken'];
  }

  getRefreshToken(): string {
    return window.localStorage['refreshToken'];
  }

  setAccessToken(token: string) {
    window.localStorage['accessToken'] = token;
    this.accessTokenSubject.next(token);
  }

  setRefreshToken(token: string) {
    window.localStorage['refreshToken'] = token;
  }

  destroyAccessToken(): void {
    window.localStorage.removeItem('accessToken');
  }

  destroyRefreshToken(): void {
    window.localStorage.removeItem('refreshToken');
  }

  refreshToken(): Observable<boolean> {
    this.apiService = this.inj.get(ApiService);

    const params = {
      'access_token': this.getAccessToken(),
      'refresh_token': this.getRefreshToken(),
    };

    return this.apiService.post<Token>('/auth/refresh', params)
      .pipe(
        map(response => {
          if (response.data && response.data.access_token) {
            this.setAccessToken(response.data.access_token);
            this.setRefreshToken(response.data.refresh_token);
            return true;
          }
          return false;
        }),
        catchError((err, caught) => {
          return throwError(() => new Error(err || this.translateService.instant('actions.serverError')));
        })
      );
  }
}
