import {
  AfterViewInit,
  Component,
  OnInit,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { TranslateService } from '@ngx-translate/core';

import {
  AppService,
  Ticket,
  TicketsService,
  ToastsService,
  User,
  UserRoles,
  UserService,
} from '../../../core';
import { AjaxDataSource } from '../../../core/services/ajax.datasource';
import { DataTableComponent } from '../../components';

@Component({
  selector: 'app-tickets-listing',
  templateUrl: './tickets.component.html',
  styleUrls: ['./tickets.component.scss'],
})
export class TicketsComponent extends DataTableComponent implements OnInit, AfterViewInit {
  loading = false;
  user: User;
  displayedColumns: Array<string> = ['title', 'source', 'content', 'creator', 'created_at', 'action'];
  datasource: AjaxDataSource<Ticket>;
  defaultFilters: { q: string } = { q: '' };
  filterService: string = 'ticketsService';
  filterStorage: string = '_filterAdminTickets';
  tickets: Ticket[] = [];

  constructor(
    private appService: AppService,
    private translateService: TranslateService,
    private dialog: MatDialog,
    private toastsService: ToastsService,
    private userService: UserService,
    private ticketsService: TicketsService,
  ) {
    super();
    this.appService.title = 'pages.tickets.list';
    this.appService.active = 'tickets';
    this.datasource = new AjaxDataSource<Ticket>();
  }

  reload() {
    this.updateDataSource(this.ticketsService.fetchTickets(this.ajaxParams.getHttpParams()));
  }

  ngOnInit(): void {
    this.userService.currentUser.subscribe(user => {
      this.user = user;
    });
  }

  isEditAllowed(ticket: Ticket): boolean {
    return this.user.role === UserRoles.ADMIN || ticket.created_by === this.user.id;
  }
}
