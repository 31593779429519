import {
  HttpContext,
  HttpParams,
} from '@angular/common/http';
import { Injectable } from '@angular/core';

import { map } from 'rxjs/operators';

import { ApiService } from './api.service';
import { BaseService } from './base.service';

import { REQUEST_ID } from '../../shared';
import {
  PaginationContent,
  RequestIds,
  User,
} from '../models';

@Injectable()
export class UsersService extends BaseService {
  constructor(
    private apiService: ApiService,
  ) {
    super();
  }

  getUser(id: number | string) {
    return this.apiService.get<User>('/admin/users/' + id).pipe(map(response => response.data));
  }

  fetchUsers(params?: HttpParams) {
    if (!params) {
      params = new HttpParams();
    }
    return this.apiService.get<PaginationContent<User>>('/admin/users', params).pipe(map(response => response.data));
  }

  createUser(params: object) {
    const context = new HttpContext().set(REQUEST_ID, RequestIds.USER_CREATE);

    return this.apiService.post<User>('/admin/users', params, false, context)
      .pipe(map(
        response => {
          if (response.data) {
            return response.data;
          }
        }
      ));
  }

  updateUser(id: number, params: object, upload = false) {
    const context = new HttpContext().set(REQUEST_ID, RequestIds.USER_UPDATE);

    return this.apiService.patch<User>('/admin/users/' + id, params, upload, context)
      .pipe(map(
        response => {
          if (response.data) {
            return response.data;
          }
        }
      ));
  }

  deleteUser(id: number) {
    return this.apiService.delete<null>(`/admin/users/${id}`);
  }

  invite(id: number) {
    return this.apiService.post(`/admin/users/${id}/invite`);
  }

  restoreUser(id: number) {
    return this.apiService.post<null>(`/admin/users/${id}/restore`);
  }
}
