export const environment = {
  production: false,
  name: 'Dev',
  project: 'PHP ECO Support',
  apiUrl: 'https://onix-systems-php-eco-support-backend.dev.onix.team',
  apiVersion: 'v1',
  frontendUrl: 'https://onix-systems-php-eco-support-frontend.staging.onix.ua/',
  app: 'user',
  apps: {
    admin: 'https://admin.onix-systems-php-eco-support-frontend.staging.onix.ua',
    user: 'https://onix-systems-php-eco-support-frontend.staging.onix.ua',
  },
  debug: true,
};
