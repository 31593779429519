<div class="page-container">
  <div>
    <div class="spinner" *ngIf="datasource.loading$ | async">
      <mat-progress-spinner color="primary" mode="indeterminate" diameter="50">
      </mat-progress-spinner>
    </div>
    <div class="row mb-3">
      <div class="col d-flex align-items-center justify-content-end gap-1 mt-md-3 mt-lg-0">
        @if (project) {
          <button class="action-button" mat-flat-button color="primary" type="button" routerLink="/projects/{{project.id}}/edit">
            {{'projects.edit.title' | translate}}
            <mat-icon>work</mat-icon>
          </button>
        }
      </div>
    </div>
    <app-project-members
      [projectId]="projectId || 0"
      [teamId]="project?.team_id || 0"
    ></app-project-members>
    <div class="row mb-3">
      <div class="col d-flex align-items-center justify-content-end gap-1 mt-md-3 mt-lg-0">
        @if (project) {
          <button class="action-button" mat-flat-button color="primary" type="button" routerLink="/projects/{{project.id}}/tickets/add">
            {{'tickets.create.title' | translate}}
            <mat-icon>assignment</mat-icon>
          </button>
        }
      </div>
    </div>
    <div class="table-container">
      <table mat-table [dataSource]="datasource" aria-label="tickets" matSort>
        <ng-container matColumnDef="title">
          <th class="w-25" scope="row" mat-header-cell *matHeaderCellDef>
            {{'tickets.show.title' | translate}}
          </th>
          <td mat-cell *matCellDef="let ticket"> {{ ticket.title }} </td>
        </ng-container>
        <ng-container matColumnDef="content">
          <th class="w-50" scope="row" mat-header-cell *matHeaderCellDef>
            {{'tickets.show.content' | translate}}
          </th>
          <td mat-cell *matCellDef="let ticket"> {{ ticket.content }} </td>
        </ng-container>
        <ng-container matColumnDef="action">
          <th class="w-10 text-right" scope="row" mat-header-cell *matHeaderCellDef disabled>
            {{'actions.action' | translate}}
          </th>
          <td mat-cell *matCellDef="let ticket" class="text-right">
            <div class="d-flex align-items-center justify-content-end">
              <button mat-icon-button color="accent" type="button" routerLink="/tickets/{{ticket.id}}">
                <mat-icon class="ticket-view-icon">remove_red_eye</mat-icon>
              </button>
            </div>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </div>
    <mat-paginator #pagination
                   [pageSizeOptions]="pageSizeOptions"
                   [length]="datasource.total$ | async">
    </mat-paginator>
  </div>
</div>
