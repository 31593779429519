import {
  AfterViewInit,
  Component,
  OnInit,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  ActivatedRoute,
  Router,
} from '@angular/router';

import { TranslateService } from '@ngx-translate/core';

import {
  AppService,
  ConfirmDialog,
  Team,
  TeamMember,
  TeamMemberRoles,
  TeamMemberService,
  TeamsService,
  ToastsService,
  User,
  UserAvatarSize,
  UserRoles,
  UserService,
} from '../../../../core';
import { AjaxDataSource } from '../../../../core/services/ajax.datasource';
import {
  ConfirmDialogComponent,
  DataTableComponent,
} from '../../../components';

@Component({
  selector: 'app-team-view',
  templateUrl: './view.component.html',
  styleUrl: './view.component.scss',
})
export class TeamViewComponent extends DataTableComponent implements AfterViewInit, OnInit {
  teamId: number | undefined;
  team: Team;
  teamMembers: Array<TeamMember>;
  currentMember: TeamMember | null;
  displayedColumns: Array<string> = ['avatar', 'first_name', 'role', 'email', 'action'];
  datasource: AjaxDataSource<TeamMember>;
  avatarSize = UserAvatarSize.SMALL;
  defaultFilters: {} = {};
  filterService: string = 'teamsService';
  filterStorage: string = '_filterAdminTeamView';
  loading = false;
  canRemoveMembers: boolean = false;

  constructor(
    private appService: AppService,
    private teamMemberService: TeamMemberService,
    private route: ActivatedRoute,
    private translateService: TranslateService,
    private teamsService: TeamsService,
    private router: Router,
    private dialog: MatDialog,
    private userService: UserService,
    private toastsService: ToastsService,
  ) {
    super();
    this.appService.title = 'pages.teams.show';
    this.appService.active = 'teams';
    this.datasource = new AjaxDataSource<TeamMember>();
  }

  ngOnInit(): void {
    this.teamId = parseInt(this.route.snapshot.paramMap.get('id'));
    if (this.teamId) {
      this.teamsService.getTeam(this.teamId).subscribe({
        next: (team: Team) => {
          this.team = team;
          this.appService.title = this.translateService.instant('pages.teams.show', {
            name: this.team.name,
          });
        },
        error: () => {
          this.router.navigateByUrl('/teams');
        },
      });
    }
    this.userService.currentUser.subscribe({
      next: (user: User) => {
        const member: (TeamMember | null) = user.members?.find((member: TeamMember) => {
          return member.team_id === this.teamId;
        });

        this.canRemoveMembers = (member?.role === TeamMemberRoles.HEAD) || user.role === UserRoles.ADMIN;
        this.currentMember = member;
      },
    });
  }

  reload(): void {
    if (this.teamId) {
      const service = this.teamMemberService.fetchTeamMembers(this.teamId, this.ajaxParams.getHttpParams());
      this.updateDataSource(service);
      service.subscribe(data => {
        this.teamMembers = data.list;
      });
    }
  }

  navigateInvitePage(): void {
    this.router.navigateByUrl(`/teams/${this.teamId}/invite`);
  }

  openRemoveTeamMemberModal(member: TeamMember): void {
    const dialogData: ConfirmDialog = {
      title: this.translateService.instant(
        `messages.deleteTeamMember.title`,
        { firstName: member.member?.first_name, lastName: member.member?.last_name }
      ),
      message: this.translateService.instant(`messages.deleteTeamMember.message`),
    };
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: dialogData,
    });

    dialogRef.afterClosed().subscribe({
      next: (dialogResult): void => {
        if (dialogResult) {
          this.removeTeamMember(member);
        }
      },
    });
  }

  openLeaveTeamModal(): void {
    const dialogData: ConfirmDialog = {
      title: this.translateService.instant(
        'messages.leaveTeam.title',
        { teamName: this.team.name }
      ),
      message: this.translateService.instant(`messages.leaveTeam.message`),
    };
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: dialogData,
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        this.removeTeamMember(this.currentMember, true);
      }
    });
  }

  private removeTeamMember(member: TeamMember, selfDelete: boolean = false): void {
    this.loading = true;

    this.teamMemberService.deleteTeamMember(member.id).subscribe({
      next: () => {
        this.loading = false;
        this.reload();
        this.toastsService.add(
          this.translateService.instant(
            selfDelete ? 'messages.leaveTeam.success' : 'messages.deleteTeamMember.success'
          )
        );

        if (this.currentMember?.id === member.id) {
          this.userService.populate();
        }
      },
      error: () => {
        this.loading = false;
      },
    });
  }
}
