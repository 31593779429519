import {
  CreateAbility,
  createMongoAbility,
  MongoAbility,
} from '@casl/ability';

type Actions = 'create' | 'read' | 'update' | 'delete';
type Subjects = 'User';

export type AppAbility = MongoAbility<[Actions, Subjects]>;
export const AppAbility = createMongoAbility as CreateAbility<AppAbility>;
