import { Media } from './media.model';
import { UserSocialite } from './social-provider.model';
import { TeamMember } from './team-member.model';

export enum UserRoles {
  ADMIN = 'admin',
  USER = 'user',
}

export enum UserStatuses {
  CREATED = 'created',
  INVITED = 'invited',
  CONFIRMED = 'confirmed',
  DELETED = 'deleted'
}

export enum UserAvatarSize {
  SMALL = '50x50',
  MEDIUM = '150x150',
  LARGE = '250x250',
  X_LARGE = '500x500'
}

export enum RequestIds {
  USER_LOGIN = 'user/login',
  USER_REGISTER = 'user/register',
  USER_CREATE = 'user/create',
  USER_UPDATE = 'user/update',
  PROFILE_CREATE = 'profile/create',
  PROFILE_UPDATE = 'profile/update',
  USER_ONBOARDING = 'user/onboarding'
}

export interface User {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
  role: string;
  status: UserStatuses;
  date_of_birth?: string | Date;
  onboarded_at?: string | Date;
  created_at?: string | Date;
  updated_at?: string | Date;
  deleted_at?: string | Date;
  invited_at?: string | Date;
  providers?: Array<UserSocialite>;

  avatar?: Media;
  documents?: Array<Media>;
  members?: Array<TeamMember>
}
