<div class="px-12 justify-content-center flex-form">
  <form [formGroup]="createProject" (ngSubmit)="onSubmit()">
    <div class="flex-center-form flex-column">
      <mat-form-field appearance="fill" class="w-60">
        <mat-label>{{'form.projectName.label' | translate}}</mat-label>
        <input matInput formControlName="name">
        <mat-error>
          <app-validation-error-render label="projectName"
                                       [errors]="createProject.controls.name.errors">
          </app-validation-error-render>
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="fill" class="w-60">
        <mat-label>{{'form.projectKey.label' | translate}}</mat-label>
        <input matInput formControlName="key">
        <mat-error>
          <app-validation-error-render label="projectKey"
                                       [errors]="createProject.get('key').errors">
          </app-validation-error-render>
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="fill" class="w-60">
        <mat-label>{{'form.teamId.label' | translate}}</mat-label>
        <mat-select formControlName="team_id">
          <mat-option *ngFor="let team of teams" [value]="team.teamId">{{ team.teamName }}</mat-option>
        </mat-select>
        <mat-error>
          <app-validation-error-render label="teamId"
                                       [errors]="createProject.controls.team_id.errors">
          </app-validation-error-render>
        </mat-error>
      </mat-form-field>
    </div>
    <button mat-raised-button color="primary" type="submit" [disabled]="!createProject.valid">
      {{'actions.submit' | translate}}
    </button>
  </form>
</div>
